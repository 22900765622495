import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {Validaciones} from '@renedelangel/helpers';
// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


import { getUsuarios, tablaUsuarios, correoActivaCuenta } from '../../querys/Usuario/metodos';
import { addUsuarioPorAdmin, updateUsuarios, deleteUsuarios, updateUsuariosEstatus } from '../../mutations/Usuario/metodos';
import {selectEnumPerfilSinUsuario} from '../../querys/Enums/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../_layout/helpers/colores';

import { rgxCorreo } from '../../helpers/regexp';


const { trim } = Validaciones;

function Usuarios() {

    const { usuario, token } = useSelector(state => state.login);

    const cleanState = { usuarioID: null, nombre: "", usuario: "", perfilID: "", estatus: true };
    const cleanErrorState = {
        nombre: { error: false, helperText: ""  },
        usuario: { error: false, helperText: ""  },
        perfilID: { error: false, helperText: ""  },
        estatus: { error: false, helperText: ""  },

    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);

    let nombreRef  = useRef(null);
    let usuarioRef = useRef(null);
    let perfilIDRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "usuarioID", variable: "usuario" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getUsuarios /*setErrorToken*/
        })
    },{
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "usuarioID" }, {campo: "nombre"}, { campo: "usuario" }, {campo: "fechaAlta"},{campo: "perfil"}],
        disabled: { multiseleccion: true },
        onClick: ({ usuarioID, usuario = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `a la paqueteria ${usuario}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { usuarioID }, asyncDelete: deleteUsuarios /*setErrorToken*/
            })
        })
    },{
        icono: RadioButtonUncheckedIcon,
        color: success,
        descripcion: "Cambiar estatus",
        parametros: [{ campo: "estatus" },{campo: "usuarioID"}],
        disabled: { multiseleccion: true },
        onClick: ({ usuarioID, estatus }) => handleAlertGeneric({
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de ${estatus ? "desactivar" : "activar"} el estatus del usuario, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, ${estatus ? "desactivalo" : "activalo"}!`,
                msjCancelacion: "Mejor no, gracias"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                mensajes: {
                    msjEspera: { descripcion: "Espere unos momentos estamos a punto de cambiar el estatus" },
                    msjCorrecto: {
                        title: "¡Se cambio el estatus!",
                        descripcion: `El estatus se cambio exitosamente a ${estatus ? "desactivado" : "activado"}`,
                        msjConfirmacion: "Gracias"
                    }
                },
                parametros: { input: { usuarioID, estatus: !estatus }}, asyncMutation: updateUsuariosEstatus /*setErrorToken*/
            })
        })
    },{
        icono: MailOutlineIcon,
        color: rose,
        descripcion: "Reenviar correo",
        parametros: [{ campo: "usuarioID", variable: "usuario" }],
        disabled: { multiseleccion: true },
        onClick: ({  usuario }) => handleAlertGeneric({
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de ${usuario ? "reenviar" : "no reenviar"} el correo de activación, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, ${usuario ? "reenviar" : "no reenviar"}!`,
                msjCancelacion: "Mejor no, gracias"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                mensajes: {
                    msjEspera: { descripcion: "Espere unos momentos estamos a punto de enviar el correo de activación" },
                    msjCorrecto: {
                        title: "¡Se reenvio el correo de activación!",
                        msjConfirmacion: "Gracias"
                    }
                },
                parametros: { usuario }, asyncMutation: correoActivaCuenta /*setErrorToken*/
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    },{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "usuarioID",
        color: primary,
        title: "Usuarios",
        iconTable: <SupervisorAccountIcon />,
        headers: [
            { variable: "usuarioID",    descripcion: "ID" },
            { variable: "nombre",       descripcion: "Nombre" },
            { variable: "usuario",      descripcion: "Usuario" },
            { variable: "fechaAlta",    descripcion: "Fecha Alta" },
            { variable: "perfilID",       descripcion: "Perfil" },
            { variable: "estatus",      descripcion: "Estatus" },

        ],
        responsiveTitle: ["usuarioID", "nombre","usuario", "fechaAlta", "perfilID","estatus"],
        filter: [
            { campo: "nombre" },


        ],
        alineacion: [{ columnas: [0,6], alineacion: "center" }],
        formato: [
            { columnas: ["fechaAlta"], tipo: "fecha"},
            { columnas: ["estatus"], tipo: "estatus" }],
    }

    const inputs = [{
        disabled,
        id: "nombre",
        value: state.nombre,
        error: errorState.nombre.error,
        success: state.nombre && !errorState.error ? true : undefined,
        helperText: errorState.nombre.helperText,
        inputRef: nombreRef,
        title: "Nombre*",
        placeholder: "Capture el nombre",
        grid: {  md: 6, lg: 6 },
        onChange: ({target:{value}}) =>handleChange(value, "nombre"),
        onKeyDown: (evt) => handleFocus(evt,nombreRef)
    },{
        disabled,
        id: "usuario",
        value: state.usuario,
        error: errorState.usuario.error,
        success: state.usuario && !errorState.usuario.error ? true : undefined,
        helperText: errorState.usuario.helperText,
        inputRef: usuarioRef,
        title: "Usuario*",
        placeholder: "Capture el usuario",
        grid: {  md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "usuario"),
        onKeyDown: (evt) => handleFocus(evt, usuarioRef)
    },{
        disabled,
        id: "perfilID",
        value: state.perfilID,
        error: errorState.perfilID.error,
        success: state.perfilID && !errorState.perfilID.error ? true : undefined,
        helperText: errorState.perfilID.helperText,
        inputRef: perfilIDRef,
        title: "Tipo de perfil",
        placeholder: "Seleccionar perfil",
        tipo: "select",
        data: seleccionables.perfilID,
        grid: {  md: 6, lg: 6 },
        onChange: ({target:{value}}) => handleChange(value, "perfilID"),
        onKeyDown: (evt) => handleFocus(evt, perfilIDRef)
    },{
        disabled,
        id: "estatus",
        value: state.estatus,
        checked: state.estatus,
        error: errorState.estatus.error,
        success: state.estatus && !errorState.estatus.error ? true : undefined,
        helperText: errorState.estatus.helperText,
        title: "Estatus ",
        placeholder: "Seleccionar el estatus",
        tipo: "switch",
        data: seleccionables.estatus,
        grid: { md: 8, lg: 4 },
        onChange: () => setState(state => ({ ...state, estatus: !state.estatus}))
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: usuarioRef,
            mensajeCorrecto: `El usuario se ${state.usuarioID ? "modifico" : "agrego"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    },{
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ nombre, usuario }) {

        let error;
        let validaciones = [{
            condicion: !nombre || trim(nombre) === "",
            ref: nombreRef, keyError: "nombre",
            mensajeError: "Para que podamos funcionar bien necesitamos que ingreses"
        },{
            /* condicion: !rgxCorreo.test(usuario), ref: usuarioRef, keyError: "usuario",
            mensajeError: "Para que podamos funcionar bien necesitamos que se defina el usuario de la cuenta del correo electrónico" */
            condicion: !usuario || trim(usuario) === "",
            ref: usuarioRef, keyError: "usuario",
            mensajeError: "Para que podamos funcionar bien necesitamos que ingreses"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }


    function ftGuardar() {
        async function ftGuardar() {

            let {usuarioID,nombre,usuario,perfilID,estatus,password,generoID, cumpleanios,fechaAlta,ultimoLogin, perfil} = state;

            let error = await handleValidaciones({ usuarioID,nombre,usuario,perfil,password,estatus,generoID, cumpleanios,fechaAlta,ultimoLogin, perfil });

            if(error) return error;

            if(usuarioID) await updateUsuarios(
            {
                input: { usuarioID, nombre: trim (nombre), usuario, perfilID, password, estatus, generoID, cumpleanios, fechaAlta,ultimoLogin, perfil }
            }, token);
            else await addUsuarioPorAdmin(
            {
                input: { nombre: trim (nombre), usuario, perfilID, password:trim (password) , estatus, generoID, cumpleanios,ultimoLogin, perfil }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftEffect() {
        async function effect() {
            try {
                let perfilID = await selectEnumPerfilSinUsuario({ perfilID: null }, token);

                setSeleccionables(seleccionables => ({ ...seleccionables, perfilID }));


            } catch({message}) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return((token) ? (usuario.usuarioID ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaUsuarios}
            token={token}
        />
        <FormularioModal
            open={open}
            title={`${state.usuarioID ?"Modificar" : "Agregar"} usuario`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </> : <Preloader />) :<><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Usuarios;
