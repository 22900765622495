import React, { useState, useRef, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import { Switch, Redirect } from 'react-router-dom';


// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

// material-ui icons

import DeleteIcon from '@material-ui/icons/Delete';
import GradeIcon from '@material-ui/icons/Grade';
import Edit from "@material-ui/icons/Edit";
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';


import { tablaProductos, getProducto, updateProducto, updateImagenes } from '../../../querys/Productos/metodos';


import { handleErrorInputText, handleModificar, handleGuardar, handleFocus } from '../../../_layout/helpers/handles';
import { danger, primary, info } from '../../../_layout/helpers/colores';
import { actualizaArchivo } from '../../../helpers/serverStorage';


import { urlServidor } from '../../../helpers';
import { ImageSearchRounded } from '@material-ui/icons';
const { trim } = Validaciones;
import defaultImage from "../../../_layout/assets/img/image_placeholder.jpg";

function ProductosAdmin() {

    const { token, usuario } = useSelector(state => state.login);
    const {usuarioID} = usuario

    const cleanState = {
        foto1: null,
        foto2:  null,

    };
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables] = useState({});
    cleanErrorState["usuarioID"] =  { error: false, helperText: ""  };
    cleanState["usuarioID"] = 0;

    const fotos = {
    foto1 : { valor: "", file: "", eliminar: false},
    foto2 : { valor: "", file: "", eliminar: false},
    foto3 : { valor: "", file: "", eliminar: false},
    foto4 : { valor: "", file: "", eliminar: false}
    }
    const [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [stFotografias, setFotografias] = useState(fotos);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);
    const inputs = [];
    const InputsConID = [];
    const Secciones = [];
    let ArrayRef = [];

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "productoID"}],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getProducto /*setErrorToken*/
        })

    }];
    InicializarInputs("productoID", "Producto ID", "Capture el ID", "", "", 100);
    InicializarInputs("codigoProducto", "Codigo producto", "Capture el ID", "", "", 100);
    InicializarInputs("descripcionCorta", "Descripción corta", "Capture la descripción corta", "", "", 10);
    InicializarInputs("tiempoEntrega", "Tiempo de entrega en días", "Capture el tiempo de entrega", "", "", 10);

    InicializarInputs("ventaOnline", "Venta Online", "Se encuentra ativo", 0, "");
    InicializarInputs("vntaExistCero", "Vender Sin Existencia", "Capture si es venta sin existencia",0, "");
    InicializarInputs("entregaDomicilio", "Tiene entrega a domicilio", "Capture si es entrega a domicilio",0, "");
    InicializarInputs("mostrarPaginaPrincipal", "Mostrar en página inicial", "", 0);

    InicializarInputs("urlImagen1", "", "",0, "Fotos del producto");
    InicializarInputs("urlImagen2", "", "",0, "Fotos del producto");
    InicializarInputs("urlImagen3", "", "",0, "Fotos del producto");
    InicializarInputs("urlImagen4", "", "",0, "Fotos del producto");
    InicializarInputs("leyenda", "", "",0, "Fotos del producto");


    InputsConID["productoID"].grid = { md: 6, lg: 3 };
    InputsConID["codigoProducto"].grid = { md: 6, lg: 3 };
    InputsConID["descripcionCorta"].grid = { md: 6, lg: 6 };
    InputsConID["ventaOnline"].grid = { md: 3, lg: 3 };
    InputsConID["tiempoEntrega"].grid = { md: 3, lg: 3 };
    InputsConID["vntaExistCero"].grid = { md: 3, lg: 3 };
    InputsConID["entregaDomicilio"].grid = { md: 3, lg: 3 };
    InputsConID["mostrarPaginaPrincipal"].grid = { md: 3, lg: 3 };
    InputsConID["urlImagen1"].grid = { xs:12, md: 6, lg: 3};
    InputsConID["urlImagen2"].grid = { xs:12, md: 6, lg: 3};
    InputsConID["urlImagen3"].grid = { xs:12, md: 6, lg: 3};
    InputsConID["urlImagen4"].grid = { xs:12, md: 6 , lg:3 };
    InputsConID["leyenda"].grid = { xs:12, md: 12 , lg:12 };

    //-------------------------------------------
    InputsConID["productoID"].disabled = true;
    InputsConID["codigoProducto"].disabled = true;
    InputsConID["descripcionCorta"].disabled = true;
    //-------------------------------------------
    InputsConID["ventaOnline"].tipo = "switch";
    InputsConID["ventaOnline"].value = stDatos["ventaOnline"];
    InputsConID["ventaOnline"].checked = (stDatos["ventaOnline"] === 1 ? true : false);
    InputsConID["ventaOnline"].onChange = ({target}) => setState({ ...stDatos, ["ventaOnline"]: target.checked ? 1:0});

    InputsConID["vntaExistCero"].tipo = "switch";
    InputsConID["vntaExistCero"].value = stDatos["vntaExistCero"];
    InputsConID["vntaExistCero"].checked = (stDatos["vntaExistCero"] === 1 ? true : false);
    InputsConID["vntaExistCero"].onChange = ({target}) => setState({ ...stDatos, ["vntaExistCero"]: target.checked ? 1:0});

    InputsConID["entregaDomicilio"].tipo = "switch";
    InputsConID["entregaDomicilio"].value = stDatos["entregaDomicilio"];
    InputsConID["entregaDomicilio"].checked = (stDatos["entregaDomicilio"] === 1 ? true : false);
    InputsConID["entregaDomicilio"].onChange = ({target}) => setState({ ...stDatos, ["entregaDomicilio"]: target.checked ? 1:0});

    InputsConID["mostrarPaginaPrincipal"].tipo = "switch";
    InputsConID["mostrarPaginaPrincipal"].value = stDatos["mostrarPaginaPrincipal"];
    InputsConID["mostrarPaginaPrincipal"].checked = (stDatos["mostrarPaginaPrincipal"] === 1 ? true : false);
    InputsConID["mostrarPaginaPrincipal"].onChange = ({target}) => setState({ ...stDatos, ["mostrarPaginaPrincipal"]: target.checked ? 1:0});

    InputsConID["urlImagen1"].tipo = stDatos.esLink1 ?  "image" : "imageupload";
    InputsConID["urlImagen1"].removeImage = stDatos.foto1;
    InputsConID["urlImagen1"].title = "Portada";
    InputsConID["urlImagen1"].sizeMax = 5000;

    InputsConID["urlImagen1"].onChange = handleSetImagen1;

    InputsConID["urlImagen2"].tipo = stDatos.esLink2 ?  "image" :  "imageupload";
    InputsConID["urlImagen2"].removeImage = stDatos.foto2;
    InputsConID["urlImagen2"].title = "Foto 2";
    InputsConID["urlImagen2"].disabled = stDatos.urlImagen1 !== "" ? false : true ;
    InputsConID["urlImagen2"].onChange = handleSetImagen2

    InputsConID["urlImagen3"].tipo = stDatos.esLink3?  "image" : "imageupload";
    InputsConID["urlImagen3"].removeImage = stDatos.foto3;
    InputsConID["urlImagen3"].title = "Foto 3";
    InputsConID["urlImagen3"].disabled = stDatos.urlImagen2 !== "" ? false : true ;
    InputsConID["urlImagen3"].onChange = handleSetImagen3

    InputsConID["urlImagen4"].tipo = stDatos.esLink4 ?  "image" :  "imageupload";
    InputsConID["urlImagen4"].removeImage = stDatos.foto4;
    InputsConID["urlImagen4"].title = "Foto 4";
    InputsConID["urlImagen4"].disabled = stDatos.urlImagen3 !== "" ? false : true ;
    InputsConID["urlImagen4"].onChange = handleSetImagen4
    InputsConID["leyenda"].tipo = "label";
    InputsConID["leyenda"].title = "Las imágenes deben tener un tamaño máximo de 10 MB y una resolución mínima de 300px";

    function handleSetImagen1(foto1) {
        let remover = false;
        if(foto1 === null){
            remover = true;
        }

        let errorValida = validaTamañoImagen(foto1, 'La imagen de portada', "urlImagen1");
      //  if (errorValida) return;

        setFotografias( {...stFotografias, foto1:{ IDimagen: stDatos.IDimagen1, url:stDatos.urlImagen1, orden:1,  file: foto1, eliminar:remover}});

        setState({ ...stDatos, foto1, urlImagen1: null });

    }
    function handleSetImagen2(foto2) {
        let remover = false;
        if(foto2 === null){
            remover = true;
        }

        let errorValida = validaTamañoImagen(foto2, 'La foto 2',  "urlImagen2");

        setFotografias( {...stFotografias, foto2:{ IDimagen: stDatos.IDimagen2, url:stDatos.urlImagen2, orden:2,  file: foto2, eliminar:remover}});

        setState({ ...stDatos, foto2, urlImagen2: null });
    }
    function handleSetImagen3(foto3) {
        let remover = false;
        if(foto3 === null){
            remover = true;
        }

        let errorValida = validaTamañoImagen(foto3, 'La foto 3',  "urlImagen3");

        setFotografias( {...stFotografias, foto3:{ IDimagen: stDatos.IDimagen3, url:stDatos.urlImagen3, orden:3,  file: foto3, eliminar:remover}});

        setState({ ...stDatos, foto3, urlImagen3: null });
    }
    function handleSetImagen4(foto4) {
        let remover = false;
        if(foto4 === null){
            remover = true;
        }

        let errorValida = validaTamañoImagen(foto4, 'La foto 4',  "urlImagen4");

        setFotografias( {...stFotografias, foto4:{ IDimagen: stDatos.IDimagen4, url:stDatos.urlImagen4, orden:4, file: foto4, eliminar:remover}});

        setState({ ...stDatos, foto4, urlImagen4: null });
    }
    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0){

        cleanErrorState[Identificador] =  { error: false, helperText: ""  };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if(pTitulo === ""){
            pTitulo = Identificador;
        }

        if(pPlaceholder === ""){
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({target:{value}}) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => {return;} };

        InputsConID[Identificador] = tInput;
        if(Seccion===""){
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if(Secciones[Seccion]){
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        }else{
            InputFieldSet = {id: Seccion,
                            leyenda: Seccion,
                            placeholder: Seccion,
                            tipo: "fieldset",
                            sizeLetra: "1.05em",
                            grid: { xs: 12, md: 12, lg: 12 },
                        };
            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }
    function handleChange(value, key, MaxLength=0) {

        if(key === "tiempoEntrega"){
            let isNumber = !isNaN(Number(value));
            if((isNumber === false)){
                return;
            }
        }
        if(value.length > MaxLength && MaxLength !== 0){
            return;
        }
        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "" , error: false }
        }));

    }
    function validaTamañoImagen(foto, nombre, key){

        setNotificaciones(notificaciones => ({
                ...notificaciones,
                mensaje: "" ,
                open: false,
                color: info
        }));
        setErrorState({ ...cleanErrorState });
        let error = false;

        if(foto === null){
            return error;
        }

        let sizeByte = foto.size;
        let sizekiloByte = parseInt(sizeByte / 1024);

        if(sizekiloByte > 10000){
            setNotificaciones(notificaciones => ({
                ...notificaciones,
                mensaje: `${nombre} supera el limite de tamaño permitido`,
                open: true,
                color: danger
            }));
            error = true;
            setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText:  `${nombre} supera el limite de tamaño permitido` , error: true }
            }));
            return error;
        }

    }
    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["productoID"],
            mensajeCorrecto: `El producto se ${stDatos.productoID ? "modifico" : "agrego"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const infoTabla = {
        acciones,
        actualizar,
        id: "productoID",
        color: primary,
        title: "Productos",
        iconTable: <BuildIcon />,
        headers: [
            { variable: "productoID", descripcion: "ID", hide:true},
            { variable: "imagenPortada", descripcion: "Portada"},
            { variable: "codigoProducto", descripcion: "Código"},
            { variable: "grupo", descripcion: "Grupo",},
            { variable: "subgrupo", descripcion: "SubGrupo" },
            { variable: "descripcionCorta", descripcion: "Nombre"},

        ],
        responsiveTitle: ["codigoProducto", "descripcionCorta", "grupo"],
        filter: [
            { campo: "codigoProducto" },
            { campo: "grupo" },
            { campo: "subgrupo" },
            { campo: "descripcionCorta" },

        ],
        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["codigoProducto"], tipo: "id" },
        { columnas: ["imagenPortada"], tipo: "imagen", url: "", token, titulo: "descripcionCorta" },
        { columnas: ["descripcionCorta"], tipo: "custom", callback: (value, {descripcion, error}) => {
            return error && error.value ? <span style={{color: "red"}}>{error.value}</span> : `${value}${descripcion && descripcion.value ? ` - ${descripcion.value}` : ""}`;
        } }
        ],

    }
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }
    function handleValidaciones() {

        let error

            let claves = Object.keys(errorState);
            let msjError = ''
            for(let i=0; i< claves.length; i++){
            let clave = claves[i];
            if(errorState[clave].error){

                msjError = errorState[clave].helperText;
                setNotificaciones(notificaciones => ({
                ...notificaciones,
                mensaje: errorState[clave].helperText ,
                open: true,
                color: danger
                }));
                error = true;
                setLoader(false);
                setDisabled(false);

                break;
            }
        }

        let validaciones = [
        {
            condicion: (stDatos["tiempoEntrega"]) === "",
            ref:ArrayRef["tiempoEntrega"], keyError: "tiempoEntrega",
            mensajeError: "Debe especificar los días de entrega"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {

                if(error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });

        });
        if(error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {

            setNotificaciones({ ...cleanNotificaciones });
            let error = await handleValidaciones();
            try{

            if(error) return error;

            for (const property in stFotografias) {

                if(stFotografias[property].file || stFotografias[property].eliminar){
                    let url = stFotografias[property].url;
                    let indice = url.indexOf('?');
                    let urloriginal = url.substring(0, indice);

                    let ruta = stFotografias[property].IDimagen ? urloriginal : 'productos/';

                    let result = await actualizaArchivo(ruta, stFotografias[property].file, 0, Number(stFotografias[property].IDimagen ), token, stFotografias[property].eliminar);

                    if(result ){

                        let nombre = stFotografias[property].file ? stFotografias[property].file.name : ""

                        await updateImagenes({
                        input: { empresaID:Number(stDatos.empresaID), productoID: Number(stDatos.productoID), imagenID: Number(stFotografias[property].IDimagen), nombreImagen: nombre, urlImagen:result, orden:stFotografias[property].orden, elimina: Number(stFotografias[property].eliminar)  }
                        }, token);
                    }
                }
            }
            if(stDatos["productoID"]){
                await updateProducto({
                    input: { productoID: stDatos.productoID,
                        ventaOnline: stDatos.ventaOnline,
                        vntaExistCero: stDatos.vntaExistCero,
                        tiempoEntrega: Number(stDatos.tiempoEntrega),
                        entregaDomicilio: stDatos.entregaDomicilio,
                        mostrarPaginaPrincipal: stDatos.mostrarPaginaPrincipal,
                        dominio: window.location.hostname.split('.')[0] }
                }, token);
            }
            }
            catch(error)
            {
               setNotificaciones( { mensaje: error, color: danger, open: true })
            }
        }
        return ftGuardar();

    }


    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState(cleanState);
        setErrorState({ ...cleanErrorState });
        setFotografias({...fotos});

      //  let img = document.getElementById("imgUpload");
      //  img.src = defaultImage;
    }



    return (token ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaProductos}
            token={token}
            parametrosFijos={{dominio: window.location.hostname.split('.')[0]}}

        />
        <FormularioModal
            open={open}
            title={`${stDatos.productoID ? "Modificar" : "Agregar"} Producto`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default ProductosAdmin;
