import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import moment from 'moment';

// Generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import Formulario from '../../../_layout/genericComponents/Formulario';
import Preloader from '../../../_layout/genericComponents/Preloader';

// Redux
import { useSelector } from "react-redux";

// material-ui icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EmailIcon from '@material-ui/icons/Email';
import FaceIcon from '@material-ui/icons/Face';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIcon from '@material-ui/icons/Phone';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { asyncHandleFocus, handleErrorInputText } from '../../../_layout/helpers/handles';
import { rgxPassword, rgxPhoneNumber } from '../../../helpers/regexp';
import { info, primary, danger, success } from '../../../_layout/helpers/colores';

// Queyrs
import { selectEnumSexo } from "../../../querys/Enums/metodos";
import { getUsuarios } from "../../../querys/Usuario/metodos";

// Mutations
import { updateUsuario } from "../../../mutations/Usuario/metodos";
import { Validaciones } from '@renedelangel/helpers';
const { trim } = Validaciones;

function Perfil() {

    const { usuario, token } = useSelector(state => state.login);

    const cleanState = { nombre: "", user: "", username: "", password: "", confirmPassword: "", generoID: "", cumpleanios: '', telefono: "", check: false };
    const cleanErrorState = {
        nombre: { error: false, helperText: "" },
        user: { error: false, helperText: "" },
        username: { error: false, helperText: "" },
        password: { error: false, helperText: "" },
        confirmarpassword: { error: false, helperText: "" },
        generoID: { error: false, helperText: "" },
        cumpleanios: { error: false, helperText: "" },
        telefono: {error: false, helperText: ''}
    };

    const [state, setState] = useState(cleanState);
    const [seleccion, setSeleccion] = useState({});
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState(null);

    const title = "Cuenta";
    const grid = { xs: 12, sm: 12, md: 12, lg: 12 };


    let nombreRef = useRef(null);
    let usernameRef = useRef(null);
    let passwordRef = useRef(null);
    let confirmarpasswordRef = useRef(null);
    let cambiarRef = useRef(null);
    let userRef = useRef(null);
    let telefonoRef = useRef(null);
    let cumpleaniosRef = useRef(null);


    const inputs = [
        {
            id: "Perfil",
            tipo: "fieldset",
            grid: { md: 12, lg: 12 },
            leyenda: `Datos personales`,
            inputs: [
                {
                    disabled: true,
                    id: "username",
                    value: state.username,
                    error: errorState.username.error,
                    success: state.username && !errorState.username.error ? true : undefined,
                    helperText: errorState.username.helperText,
                    title: "Correo electrónico *",
                    placeholder: "Capture su correo electrónico",
                    icono: <EmailIcon />,
                    inputRef: usernameRef,
                    onChange: ({ target: { value: username } }) => setState(state => ({ ...state, username })),
                    onKeyDown: (evt) => asyncHandleFocus(evt, passwordRef)
                }, {
                    disabled,
                    id: "nombre",
                    value: state.nombre,
                    error: errorState.nombre.error,
                    success: state.nombre && !errorState.nombre.error ? true : undefined,
                    helperText: errorState.nombre.helperText,
                    title: "Nombre *",
                    placeholder: "Capture su nombre completo",
                    icono: <FaceIcon />,
                    inputRef: nombreRef,
                    onChange: ({ target: { value: nombre } }) => setState(state => ({ ...state, nombre })),
                    onKeyDown: (evt) => asyncHandleFocus(evt, usernameRef)
                },
                {
                    disabled,
                    id: "user",
                    value: state.user,
                    error: errorState.user.error,
                    success: state.user && !errorState.user.error ? true : undefined,
                    helperText: errorState.user.helperText,
                    title: "Usuario *",
                    placeholder: "Capture su usuario",
                    icono: <AssignmentIndIcon />,
                    inputRef: userRef,
                    onChange: ({ target: { value: user } }) => setState(state => ({ ...state, user })),
                    onKeyDown: (evt) => asyncHandleFocus(evt, userRef)
                },{
                    disabled,
                    id: "telefono",
                    value: state.telefono,
                    error: errorState.telefono.error,
                    success: state.telefono && !errorState.telefono.error ? true : undefined,
                    helperText: errorState.telefono.helperText,
                    title: "Teléfono *",
                    placeholder: "Capture su teléfono",
                    icono: <PhoneIcon />,
                    inputRef: telefonoRef,
                    onChange: ({ target: { value: telefono } }) => setState(state => ({ ...state, telefono })),
                    onKeyDown: (evt) => asyncHandleFocus(evt, telefonoRef)
                },
                {
                    disabled,
                    id: "generoID",
                    value: state.generoID ? state.generoID : "",
                    error: errorState.generoID.error,
                    success: state.generoID && !errorState.generoID.error ? true : undefined,
                    helperText: errorState.generoID.helperText,
                    title: "Sexo",
                    placeholder: "Seleccione su sexo",
                    data: seleccion.sexos,
                    tipo: "select",
                    grid: { xs: 6, lg: 6 },
                    onChange: ({ target: { value } }) => handleState(value, "generoID"),
                }, {
                    disabled,
                    id: "cumpleanios",
                    value: state.cumpleanios,
                    error: errorState.cumpleanios.error,
                    success: state.cumpleanios && !errorState.cumpleanios.error ? true : undefined,
                    helperText: errorState.cumpleanios.helperText,
                    title: "Fecha de nacimiento",
                    placeholder: "Fecha de nacimiento",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { xs: 6, lg: 6 },
                    inputRef: cumpleaniosRef,
                    onChange: (value) => handleState(value, "cumpleanios"),
                }, {
                    disabled,
                    id: "password",
                    value: state.password,
                    error: errorState.password.error,
                    success: state.password && !errorState.password.error ? true : undefined,
                    helperText: errorState.password.helperText,
                    title: "Nueva contraseña",
                    placeholder: "Capture su nueva contraseña",
                    icono: <VpnKeyIcon />,
                    inputProps: { type: "password" },
                    inputRef: passwordRef,
                    onChange: ({ target: { value } }) => handleState(value, "password"),
                    onKeyDown: (evt) => asyncHandleFocus(evt, confirmarpasswordRef, true)
                }, {
                    disabled,
                    id: "confirmarpassword",
                    value: state.confirmarpassword,
                    error: errorState.confirmarpassword.error,
                    success: state.confirmarpassword && !errorState.confirmarpassword.error ? true : undefined,
                    helperText: errorState.confirmarpassword.helperText,
                    title: "Confirmar contraseña",
                    placeholder: "Vuelva a capturar su nueva contraseña",
                    icono: <VpnKeyIcon />,
                    inputProps: { type: "password" },
                    inputRef: confirmarpasswordRef,
                    onChange: ({ target: { value } }) => handleState(value, "confirmarpassword"),
                    onKeyDown: (evt) => asyncHandleFocus(evt, cambiarRef, false)
                }]
        }
    ];

    const acciones = [{
        loader,
        disabled,
        icono: ArrowRightIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: ftActualizar,
        inputRef: cambiarRef
    }];

    function handleState(value, id) {
        setState(state => ({
            ...state,
            [id]: value
        }));
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, errorState: setErrorState
        });
    }

    function handleValidaciones({ nombre, user, telefono, cumpleanios, password, confirmarpassword }) {

        let error;
        let validaciones = [{
            condicion: nombre === "",
            ref: nombreRef, keyError: "nombre",
            mensajeError: "Es necesario capturar un nombre correcto"
        },{
            condicion: !rgxPhoneNumber.test(telefono) || trim(telefono) === "",
            ref: usernameRef, keyError: "telefono",
            mensajeError: "Capture un télefono válido"
        },
        {
            condicion: cumpleanios !== '' && typeof(cumpleanios) !== 'object',
            ref: cumpleaniosRef, keyError: "cumpleanios",
            mensajeError: "Seleccione una fecha válida"
        },
        {
            condicion: typeof(cumpleanios) == 'object' ? cumpleanios.format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') : false,
            ref: cumpleaniosRef, keyError: "cumpleanios",
            mensajeError: "La fecha de cumpleaños no puede ser mayor a la fecha actual"
        },
        {
            condicion: password && !rgxPassword.test(password),
            ref: passwordRef, keyError: "password",
            mensajeError: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres"
        }, {
            condicion: password && !rgxPassword.test(password),
            ref: passwordRef, keyError: "password",
            mensajeError: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres"
        }, {
            condicion: password && (password !== confirmarpassword), ref: confirmarpasswordRef, keyError: "confirmarpassword",
            mensajeError: "La contraseña de confirmación no es igual a la contraseña capturada"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftActualizar() {
        async function actualizar() {
            setLoader(true);
            setDisabled(true);
            try {

                let { usuarioID, usuario, user, nombre, perfilID, generoID, cumpleanios, estatus, password, confirmarpassword, fechaAlta, telefono } = state;

                let error = await handleValidaciones({ nombre, user, telefono, cumpleanios, password, confirmarpassword });

                if (error) {
                    setDisabled(false);
                    return error;
                }

                let cambio = await updateUsuario({
                    input: {
                        usuarioID,
                        usuario,
                        nombre,
                        user,
                        perfilID,
                        generoID: generoID ? generoID : null,
                        cumpleanios: cumpleanios ? cumpleanios.format("YYYY-MM-DD") : null,
                        estatus: !!estatus,
                        password: password === "" ? null : password,
                        // estatusID,
                        fechaAlta: fechaAlta,
                        telefono
                    }
                }, token);

                if (!cambio) throw new Error("Ocurrió un error al modificar tu información");

                setAlert({
                    descripcion: "Hemos podido actualizar tu información correctamernte",
                    title: "¡Información actualizada!",
                    tipo: success,
                    msjConfirmacion: "¡Gracias!",
                    onConfirm: () => setAlert(null)
                });

            } catch ({ message }) {
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "¡Esta bien!",
                    onConfirm: () => setAlert(null)
                });
            }
            setLoader(false);
            setDisabled(false);
        } return actualizar();
    }

    function ftEffectSelect() {
        setError(null);
        async function effectSelect() {
            try {
                let sexos = await selectEnumSexo({}, token);
                setSeleccion(seleccion => ({ ...seleccion, sexos }));
            } catch ({ message }) {
                setError(true);
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
        } effectSelect();
    }

    function ftEffect() {

        if (!usuario) return;
        let { usuarioID } = usuario;
        setError(null);
        async function effect() {
            try {
                let result = await getUsuarios({ usuario: usuarioID }, token);

                setState({ 
                    ...usuario, 
                    username: result.correo,
                    usuario: result.correo,
                    user: result.usuario,
                    nombre: result.nombre,
                    cumpleanios: result.cumpleanios == null ? '' : moment(result.cumpleanios), 
                    generoID: result.generoID ? Number(result.generoID) : null,
                    telefono: result.telefono,
                    password: null,
                    confirmPassword: null
                    }
                    );

            } catch ({ message }) {
                setError(true);
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
        } effect();
    }

    useEffect(ftEffect, []);

    useEffect(ftEffectSelect, []);

    return ((token && !error) ? (state.usuarioID ? <>

    <input style={{display: 'none'}} type="password" name="nousar" autocomplete="new-password"/>

        <Formulario
            title={title}
            icono={<AccountCircleIcon />}
            grid={grid}
            inputs={inputs}
            acciones={acciones}
            focus={passwordRef}
            color={primary}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>
    );

}

export default Perfil;
