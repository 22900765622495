import React, { useState, useEffect } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
    agregarArticuloRedux,
    setCarrito,
    modificarProductoRedux
} from "../../../redux/actions/carritoActions";
import { Switch, Redirect, useParams } from "react-router-dom";
import { Encriptar } from "@renedelangel/helpers";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getProducto } from "../../../querys/Productos/metodos";
import { upsertDeseos } from "../../../mutations/Deseos/metodos";
import { tablaProductosAlternativos } from "../../../querys/Productos/metodos";
import { upsertCarrito } from "../../../querys/Carrito/index";
import { Carousel } from "react-responsive-carousel";
import noImage from "../../assets/svg/undraw_blank_canvas_3rbb.svg";
import { redondeo } from "../../../helpers/regexp.js";
import downArrow from "../../assets/svg/downArrow.svg";
import AlertaGenerica from "../../../_layout/genericComponents/AlertaGenerica";
import CarruselProductos from "../../../_layout/components/CarruselProductos";
import { tablaProductos } from "../../../querys/Productos/metodos";
import { getAllProductosConLicenciaPorUsuario } from "../../../querys/ProductosConLicenciaPorUsuario/index";
import { toFixed } from "../../../helpers/regexp.js";
import LoaderLogoSoftwone2 from "_layout/assets/svg/Animaciones/logoSoftwone2";

const { Encrypt, Decrypt } = Encriptar;

export default function DetallesProducto(props) {
    let { id } = useParams();
    let url = id.replace(/&/g, "/");
    let seed = "softwone";
    const noTruncarDecimales = { maximumFractionDigits: 20,  style: 'currency', currency: 'MXN' };

    let datos =
        Decrypt({ data: url, seed }) !== ""
            ? JSON.parse(Decrypt({ data: url, seed }))
            : null;
    let productoID = datos.id;

    const { usuario, token } = useSelector(state => state.login);
    const { usuarioID } = usuario ? usuario : {};
    const { busqueda } = useSelector(state => state.busqueda);
    const dispatch = useDispatch();
    const agregarItemRedux = item => dispatch(agregarArticuloRedux(item));
    const modificarItemRedux = item => dispatch(modificarProductoRedux(item));
    const { carrito } = useSelector(state => state.carrito);
    const setCarritoRedux = carritoUsuario =>
        dispatch(setCarrito(carritoUsuario));
    if (!carrito) {
        setCarritoRedux([]);
    }
    const defaultMensaje = {
        visible: false,
        descripcion: "",
        tipo: "error" // Opciones: error, warning, info, success
    };

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const [state, setState] = useState({ productoID: null });
    const [actualizarProductosRelacionados, setActualizarProductosRelacionados] = useState(false);
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [carrusel, setCarrusel] = useState(null);
    const [count, setCount] = useState(1);
    const [principal, setPrincipal] = useState({
        imagen: null,
        leyenda: "0/0"
    });
    const [likeFavorito, setFavorito] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [actualizarCard, setActualizarCard] = useState({ id: 0, like: 0 });
    const [redirigir, setRedirigir] = useState(null);
    const [showDescripcion, setShowDescripcion] = useState(true);
    const [showFichaTecnica, setShowFichaTecnica] = useState(false);
    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);
    const [
        stateProductosRelacionados,
        setStateProductosRealacionados
    ] = useState([]);
    const [licenciaUnica, setLicenciaUnica] = useState(0);
    const [loading, setLoading] = useState(false);

   

    const agregarAlCarrito = carritoID => {
        let item = {
            carritoID: carritoID,
            usuarioID: usuarioID ? Number(usuarioID) : null,
            productoID: Number(productoID),
            imagenPortada: state.urlImagen1,
            codigoProducto: state.codigoProducto,
            nombre: state.descripcionCorta,
            descripcion: state.descripcion,
            cantidad: Number(count),
            precioVentaOnlineSinIva: Number(state.precioVentaOnlineSinIva),
            IVA: Number(state.precioVentaOnlineSinIva) * Number(state.porcIva / 100),
            precioVentaOnline: Number(state.precioVentaOnline),
            servicio: state.servicio,
            producto: state.producto,
            licenciaUnica: state.licenciaUnica,
            plazoPaquete: state.plazoPaquete
        };
        
        let index = carrito
            .map(item => item.productoID)
            .indexOf(Number(productoID));

        if (index > -1) {
            let cant = Number(carrito[index].cantidad) + Number(count);
            let item = carrito[index];
            let nuevo = { ...item, cantidad: cant };
            modificarItemRedux(nuevo);
        } else {
            agregarItemRedux(item);
        }
        setActualizar(true);
    };

    const incrementaCantidad = sumar => {
        let cantidad = count;
        cantidad = Number(cantidad) + Number(sumar);
        validaExistencia(cantidad);
    };
    const changeCantidad = e => {
        let cant = e.target.value;
        validaExistencia(cant);
    };
    function validaExistencia(cantidad) {
        if (cantidad < 1) {
            setCount(1);
            return;
        }
        setCount(cantidad);
        if (state.vntaExistCero == 0) {
            if (cantidad > state.existencia) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `La cantidad ${cantidad} sobrepasa la existencia disponible (${state.existencia})`,
                    tipo: "error"
                });
                setCount(state.existencia);
                return;
            }
        }
    }

   

    function ftAgregarItem() {
        async function ftAgregarItem() {
            try {
                if (state.vntaExistCero == 0) {
                    if (count > state.existencia) {
                        setAlertGenerica({
                            visible: true,
                            descripcion: `La cantidad ${count} sobrepasa la existencia disponible (${state.existencia})`,
                            tipo: "error"
                        });
                        return;
                    }
                }

                

                let productoUnico = 0;
                for (let i = 0; i < carrito.length; i++) {
                   
                    if (
                        (state.licenciaUnica == 1 && state.licenciaUnica == carrito[i].licenciaUnica &&
                        state.producto == carrito[i].producto && state.plazoPaquete == carrito[i].plazoPaquete) ||
                        (state.licenciaUnica == 1 && state.licenciaUnica == carrito[i].licenciaUnica &&
                            state.producto == carrito[i].producto && state.plazoPaquete)
                    ) {
                        
                        productoUnico = 1;
                        break;
                    }
                }

                //Validación para saber si el usuario tiene solo una
                if (licenciaUnica == 0) {
                    if (productoUnico == 0) {
                    if (usuario !== null) {
                        let index = carrito
                            .map(item => item.productoID)
                            .indexOf(Number(productoID));
                        let cant = count;
                        if (index > -1) {
                            cant =
                                Number(carrito[index].cantidad) + Number(count);
                        }

                        let carritoSelec = await upsertCarrito(
                            {
                                input: {
                                    productoID: Number(productoID),
                                    cantidad: Number(cant)
                                }
                            },
                            token
                        );
                        if (carritoSelec) {
                            agregarAlCarrito(carritoSelec.carritoID);
                        }
                    } else {
                        agregarAlCarrito(null);
                    }
                    setAlertGenerica({
                        visible: true,
                        descripcion: `Producto agregado a tu carrito de compras`,
                        tipo: "success"
                    });
                    
                } else {
                    setAlertGenerica({
                        visible: true,
                        descripcion: `¡Disculpa! Detectamos que ya tienes una variante de plan de este producto en tu carrito. Si deseas agregar este plan, modifica tu carrito`,
                        tipo: "error"
                    });
                }
                } else {
                    setAlertGenerica({
                        visible: true,
                        descripcion: `Detectamos que ya tienes una cuenta con este tipo de plan, considera mejorar el tipo de plan que tienes agregando una suscripción diferente`,
                        tipo: "error"
                    });
                }
            } catch (error) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `Error ${error}`,
                    tipo: "error"
                });
            }
        }
        return ftAgregarItem();
    }

    function ftAgregar() {
        async function ftAgregar() {
            try {
                let deseo = await upsertDeseos(
                    {
                        input: {
                            deseosID: state.deseosID,
                            productoID: Number(state.productoID),
                            clienteID: usuario.usuarioID
                        }
                    },
                    token
                );
                let msj = deseo
                    ? `Producto agregado a sus favoritos`
                    : `Se quitó el producto de sus favoritos`;
                setState({ ...state, deseosID: deseo });
                setAlertGenerica({
                    visible: true,
                    descripcion: msj,
                    tipo: "success"
                });
            } catch (error) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `Error: ${error}`,
                    tipo: "error"
                });
            }
        }
        return ftAgregar();
    }
    function agregarListaDeseos() {
        if (usuario !== null) {
            ftAgregar();
        } else {
            setFavorito(likeFavorito => true);
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }
    function agregarListaDeseosProducto() {
        if (!usuario) {
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }
    function getRederigir(url = "/") {
        return (
            <>
                <Switch>
                    <Redirect to={url} />
                </Switch>
            </>
        );
    }
    const filtraGrupo = () => {
        setGruposRedux({ gruposIDs: [state.grupoID.toString()] });
        setRedirigir(getRederigir(`/productos`));
    };
    const filtraSubGrupo = () => {
        setBusquedaRedux({ subgruposIDs: [state.subgrupoID.toString()] });
        setRedirigir(getRederigir(`/productos`));
    };
    function direccionar(datos) {
        let seed = "softwone";
        const parametros = `{"id": "${datos.id}"}`;
        let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&");
        setRedirigir(getRederigir(`${url}`));
    }

    function ftEffect() {
        async function effect() {
            setLoading(true);
            try {
                let producto = await getProducto(
                    { productoID: productoID },
                    token
                );

                if (!producto.productoID) {
                    return;
                }

                let licenciasUsuario;
                if (usuario && token) {
                    licenciasUsuario = await getAllProductosConLicenciaPorUsuario(
                        { filtro: { usuarioID: usuario.usuarioID } },
                        token
                    );

                    for (let i = 0; i < licenciasUsuario.length; i++) {
                        if (
                            producto.licenciaUnica == 1 &&
                            licenciasUsuario[i].producto == producto.producto &&
                            producto.tipoPaquete ==
                                licenciasUsuario[i].tipoPaquete &&
                            licenciasUsuario[i].periodicidad ==
                                producto.plazoPaquete
                        ) {
                            setLicenciaUnica(1);
                            break;
                        }
                    }
                }

                let precio = producto.precioVentaOnline
                    ?parseFloat(toFixed(producto.precioVentaOnline, 2))
                    : 0;
                precio = redondeo(precio, 2);
                precio = parseFloat(precio).toLocaleString("es-MX", noTruncarDecimales);
                producto.precioDetalle = precio;

                let imagenes = [];
                let total = producto.urlImagen4
                    ? 4
                    : producto.urlImagen3
                    ? 3
                    : producto.urlImagen2
                    ? 2
                    : producto.urlImagen1
                    ? 1
                    : 0;

                if (producto.urlImagen1 !== "") {
                    imagenes.push({
                        imagen: producto.urlImagen1,
                        leyenda: `1 / ${total}`,
                        orden: 0
                    });
                } else {
                    imagenes.push({
                        imagen: noImage,
                        leyenda: `1 / ${total}`,
                        orden: 0
                    });
                }
                if (producto.urlImagen2 !== "")
                    imagenes.push({
                        imagen: producto.urlImagen2,
                        leyenda: `2 / ${total}`,
                        orden: 1
                    });
                if (producto.urlImagen3 !== "")
                    imagenes.push({
                        imagen: producto.urlImagen3,
                        leyenda: `3 / ${total}`,
                        orden: 2
                    });
                if (producto.urlImagen4 !== "")
                    imagenes.push({
                        imagen: producto.urlImagen4,
                        leyenda: `4 / ${total}`,
                        orden: 3
                    });

                let productosRelacionados = await tablaProductos(
                    { filtro: { 
                        subgrupo: producto.subgrupo,
                        precioMaximo: 99999999,
                        precioMinimo: 1
                    } },
                    token
                );

                let listadoProductosRelacionados = [];
                for (let i = 0; i < productosRelacionados.listado.length; i++) {
                    const tProd = productosRelacionados.listado[i];
                    listadoProductosRelacionados.push({
                        id: tProd.productoID,
                        titulo: tProd.descripcionCorta,
                        imagen: tProd.imagenPortada,
                        descripcion: tProd.descripcion,
                        deseosID: tProd.deseosID
                    });
                }

                setStateProductosRealacionados(listadoProductosRelacionados);
                setCarrusel(imagenes);
                setPrincipal({
                    imagen: imagenes[0].imagen,
                    leyenda: imagenes[0].leyenda
                });
                setState(producto);
                setLoading(false);
            } catch ({ message }) {
                setLoading(false);
                console.error(
                    "Error en el efect Detalle del producto",
                    message
                );
            }
        }
        effect();
    }
    useEffect(ftEffect, [productoID, actualizar, actualizarProductosRelacionados, usuario, token]);

    function carouselGenerator(data) {
        if (data !== null) {
            let aux = data.map((element, index) => {
                return (
                    <div className="imagesCarouselDetalleProducto">
                        <img src={`${element.imagen}`} />
                    </div>
                    
                );
            });
          
            return (
                <>
                    <Carousel showArrows={true}>{aux}</Carousel>
                </>
            );
        }
    }

    const handleCloseMensaje = (event, reason) => {
        if (reason === "clickaway") return;
        setAlertGenerica({
            ...alertGenerica,
            visible: false
        });
    };

    return (
        <>
            <div className="contenedorGeneralDetallesProducto">
                <div className="contenedorInternoDetallesProducto">
                    <div className="contenedorCarouselDetallesProducto">
                        {
                            state.mostrarLeyenda && <div className="leyenda_imgproducto"> Imagen ilustrativa </div>
                        }
                        {
                            
                            carouselGenerator(carrusel)

                        }




                    </div>
                    <div className="contenedorDescripcionDetallesProducto">
                        <div className="descripcionDetalleProducto">
                            <h3>{state.descripcionCorta}</h3>
                        </div>
                        <div className="codigoDetalleProducto">
                            <h5>
                                {`Código del producto: #${state.codigoProducto}`}
                            </h5>
                        </div>
                        <div className="precioDetalleProducto">
                            {state.precioDetalle}
                        </div>

                        <div className="contenedorAccionesDetalleProducto">
                            <div className="controlesDetalleProducto">
                                {state?.licenciaUnica == 0 ? (
                                    <>
                                        <div
                                            className="buttonLessDetalleProducto unselectable"
                                            onClick={() => {
                                                incrementaCantidad(-1);
                                            }}
                                        >
                                            -
                                        </div>
                                        <div className="indicadorCantidadDetalleProducto">
                                            <input
                                                type="number"
                                                value={count}
                                                onChange={e =>
                                                    changeCantidad(e)
                                                }
                                            />
                                        </div>
                                        <div
                                            className="buttonMoreDetalleProducto unselectable"
                                            onClick={() => {
                                                incrementaCantidad(1);
                                            }}
                                        >
                                            +
                                        </div>{" "}
                                    </>
                                ) : (
                                    <div className="indicadorCantidadDetalleProducto">
                                        <input
                                            type="number"
                                            value={1}
                                            disabled={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className="buttonDetalleProducto"
                            onClick={() => {
                                ftAgregarItem();
                            }}
                        >
                            <h6>Agregar al carrito</h6>
                        </div>

                        <div className="descripcionLargaDetalleProducto unselectable">
                            <h5
                                onClick={() => {
                                    setShowDescripcion(!showDescripcion);
                                }}
                            >
                                Descripción
                                <span
                                    className={
                                        showDescripcion
                                            ? "arrowUpDetalleProducto"
                                            : "arrowDownDescripcionDetalleProduto"
                                    }
                                >
                                    {" "}
                                    <img src={downArrow} />{" "}
                                </span>
                            </h5>

                            <div
                                className="descripcionLargaDetalleProductoHidden"
                                style={{
                                    display: showDescripcion ? "flex" : "none"
                                }}
                            >
                                {state.descripcion}
                            </div>
                        </div>

                        <div className="descripcionLargaDetalleProducto unselectable">
                            <h5
                                onClick={() => {
                                    setShowFichaTecnica(!showFichaTecnica);
                                }}
                            >
                                Ficha Técnica
                                <span
                                    className={
                                        showFichaTecnica
                                            ? "arrowUpDetalleProducto"
                                            : "arrowDownDescripcionDetalleProduto"
                                    }
                                >
                                    {" "}
                                    <img src={downArrow} />{" "}
                                </span>
                            </h5>

                            <div
                                className="descripcionLargaDetalleProductoHidden"
                                style={{
                                    display: showFichaTecnica ? "flex" : "none"
                                }}
                            >
                                <p> {state.fichaTecnica } </p>

                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <CarruselProductos
                items={stateProductosRelacionados}
                Titulo={"PRODUCTOS RELACIONADOS"}
                Etiqueda={"Nuevo"}
                mostrarBoton={false}
                mostrarDescripcion={false}
                actualizar={actualizarProductosRelacionados} 
                setActualizar={setActualizarProductosRelacionados}
            />

            {alertGenerica && (
                <AlertaGenerica
                    visible={alertGenerica.visible}
                    descripcion={alertGenerica.descripcion}
                    tipo={alertGenerica.tipo}
                    handleClose={handleCloseMensaje}
                />
            )}

            {
                loading ? 
                <LoaderLogoSoftwone2/> 
                :
                <></>
            }
        </>
    );
}
