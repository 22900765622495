import React, {useState} from "react";
import "./styles.css";
import {
    logoMKTSoftwone,
    redes01,
    redes02,
    redes03,
    redes04
} from "./Footer/logos";
import { Link } from "react-router-dom";

import {
    avisoSoftwone,
    terminosSoftwone
} from "../../../public/Pdf/Softwone/exportPDF";

import PopUpSusccribirse from "../PopUpSuscribirse";

export default function FooterPagina(props) {

    const [openSuscribirse, setOpenSuscribirse] = useState(false);
    return (
        <>
            <div className="contenedorGeneralFooter unselectable">
                <div className="contenedorInternoFooter">
                    <div className="primeraSeccionFooter">
                        <div className="contenedorLogoFooter">
                            <Link to = {'/'}>
                            <img src={logoMKTSoftwone} />
                            </Link>
                            <div className="redesOcultas">
                                <li>
                                    <a
                                        href="https://www.facebook.com/Softwone-102991398678726"
                                        target="_blank"
                                    >
                                        <img src={redes01} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/softwone.international/"
                                        target="_blank"
                                    >
                                        <img src={redes02} />
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:mkt@softwone.com?Subject=Informacion%20Softwone">
                                        <img src={redes03} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img src={redes04} />
                                    </a>
                                </li>
                            </div>
                        </div>

                        <div className="contenedorRedes">
                            <h3>¡Siguenos en redes!</h3>
                            <div className="contenedorEnlacesRedes">
                                <li>
                                    <a
                                        href="https://www.facebook.com/Softwone-102991398678726"
                                        target="_blank"
                                    >
                                        <img src={redes01} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/softwone.international/"
                                        target="_blank"
                                    >
                                        <img src={redes02} />
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:mkt@softwone.com?Subject=Informacion%20Softwone">
                                        <img src={redes03} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img src={redes04} />
                                    </a>
                                </li>
                            </div>
                        </div>

                        <div className="contenedorSuscribirse">
                            <h3>Recibe nuestras noticias</h3>
                            <div className="botonSuscribirse" onClick={() => {setOpenSuscribirse(true)}}>
                                <h3>
                                    Suscribirse
                                </h3>
                            </div>
                        </div>

                        <div className="contenedorPoliticas">
                            <h1>Politicas</h1>
                            <div className="linksPoliticas">
                                <a href={avisoSoftwone} target="_blank">
                                    <h3>Aviso de privacidad</h3>
                                </a>
                                <a href={terminosSoftwone} target="_blank">
                                    <h3>Términos y condiciones</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="contenedorPoliticasHidden">
                        
                            <div className="linksPoliticas">
                                <a href={avisoSoftwone} target="_blank">
                                    <h3>Aviso de privacidad</h3>
                                </a>
                                <hr />
                                <a href={terminosSoftwone} target="_blank">
                                    <h3>Términos y condiciones</h3>
                                </a>
                                <hr />
                            </div>
                        </div>
                        
                    <div className="contenedorCopyright">
                        <h4>
                            Copyright © 2015 - 2022 Softwone® International,
                            S.A. de C.V. Todos los derechos reservados.
                            Prohibida la duplicación, distribución o
                            almacenamiento en cualquier medio.
                        </h4>
                    </div>
                </div>
            </div>

            {
            openSuscribirse? <PopUpSusccribirse loginInside={setOpenSuscribirse} /> : <></>  
          }
        </>
    );
}
