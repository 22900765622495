import React, {useState, useEffect} from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import icoProd from '../../../componentes/MenuLineal/icoProd.png'
import './styles.css'
import { selectGrupoERP, selectSubGrupoERP, getAllGrupoERP, getAllSubGrupoERP } from "../../../querys/Departamentos/metodos";
import { Encriptar } from '@renedelangel/helpers';
import useWindowDimensions from '../../../hooks/sizes'
const { Encrypt } = Encriptar;

export default function MenuResponsive(props){
    let {
     paginaPrincipal,
     redirigir,
     openMenuResponsive,
     setOpenMenuResponsive
    } = props;

    let tokenFijo = 'c29mdHdvbmUyMDIwQCM=';
    const seed = 'softwone';

    const [menuProductos, setMenuProductos] = useState([]);
    const [showGrupos, setShowGrupos] = useState(false);
    const [showSubgrupos, setShowSubgrupos] = useState(null);
    const pageWidth = useWindowDimensions().width;
    const [responsive, setResponsive] = useState(false);


    let menus = [
        { key: "Inicio", titulo: "Carrito", ruta: `/Carrito`, iconoMenu: null },
        /* { key: "Productos", titulo: "Productos", ruta: "", iconoMenu: icoProd },
        { key: "Nosotros", titulo: "Nosotros", ruta: "/Nosotros", iconoMenu: null },
        { key: "Contacto", titulo: "Contacto", ruta: "/Contacto", iconoMenu: null } */
    ];

    useEffect(() => {
        async function ftObtenerGruposSubgrupos() {
            let nGrupos = await getAllGrupoERP({}, tokenFijo);
            let nSubgrupos = await getAllSubGrupoERP({}, tokenFijo);

            if (nGrupos.length > 0) {
                setMenuProductos(
                    nGrupos.map(element => {
                        return {
                            grupo: element.grupo,
                            subgrupos:
                            
                                nSubgrupos.map(el => {
                                    if (el.grupoID == element.grupoID) {
                                    return el
                                    }
                                })
                        }
                    }));
            }

        }
        ftObtenerGruposSubgrupos()
    }, [])

    useEffect(() => {
        async function ftHandleCerrarResponsive(){
            if(responsive == false && pageWidth <= 768){
                {
                setResponsive(true)
            }}
            if(responsive == true && pageWidth > 768){
                setResponsive(false)
                setOpenMenuResponsive(false);
            }
        }
        ftHandleCerrarResponsive();
    }, [pageWidth])

    let menuItems = [];
        for (let i = 0; i < menus.length; i++) {
            const element = menus[i];
            if (i === 0) {
                menuItems.push(itemMenu(element.key, element.titulo, element.ruta, menus.length + " cItemInicial", element.iconoMenu));
            } else {
                menuItems.push(itemMenu(element.key, element.titulo, element.ruta, menus.length, element.iconoMenu));
            }
        }

    function itemMenu(key, titulo, ruta, d, iconoMenu) {

        if (key == 'Productos') {
            return (
                <div style={{height: 'auto'}}>
            <div className='c_each_ruta_menu_responsive' key={key} onClick={() => {setShowGrupos(!showGrupos); setShowSubgrupos(null)}} /* onMouseOver = {() => {setShowGrupos(true)}} onMouseLeave = {() => {setShowGrupos(false)}} */>
                <div className={"i_each_ruta_titulo"} >
                    {titulo}
                </div>
                {/* {iconoMenu ? <img className="iconoMenu_responsive" src={iconoMenu} /> : <></>} */}
            </div>
            {
                            <div className='c_menu_productos_responsive' style={{display: showGrupos ? 'inline-block' : 'none'}} >
                                {
                                    menuProductos.map((element, index) => {
                                        return (<>
                                            <div className='i_menu_productos_grupos_responsive'
                                            style = {{backgroundColor: showSubgrupos == index ? 'var(--secondaryColor)' : 'var(--primaryColor)' }}
                                             onClick={() => {showSubgrupos == index ? setShowSubgrupos(null) : setShowSubgrupos(index)}}>
                                           {element.grupo}
                                           </div>
                                        <div className='c_menu_productos_subgrupos_responsive' style={{display: showSubgrupos == index ? 'inline-block' : 'none'}} >
                                            {
                                                element.subgrupos?.map( el => {
                                                    if(el !== undefined){
                                                   return (<div 
                                                    className='i_menu_productos_subgrupos_responsive'
                                                   onClick={() => {
                                                    redirigir(`/products/${handleViewProducto(el.subgrupoID)}`); 
                                                    setShowGrupos(false);
                                                    setOpenMenuResponsive(!openMenuResponsive);
                                                }
                                                }
                                                   /* onClick={() => {console.log(`${el.subgrupoID}`)}} */
                                                    >
                                                        {el?.subgrupo}
                                                        
                                                </div>)}
                                                } )
                                            }
                                        </div>
                                    </>
                                        )
                                    })
                                }
                            </div>
                 
                }
            </div>);
        }

        function handleViewProducto(id){
            let parametros = `{"id": "${id}"}`;
            let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&");
            return url;
        }

        return (<div className='c_each_ruta_menu_responsive' key={key} onClick={() => {
            redirigir(ruta);
            setOpenMenuResponsive(!openMenuResponsive);
            }}>
            <div className={"i_each_ruta_titulo"}>
                {titulo}
            </div>
            {iconoMenu ? <img className="iconoMenu_responsive" src={iconoMenu} /> : <></>}
        </div>);
    }


    return (<>
    <div className='c_general_menu_responsive' onClick={() => {setOpenMenuResponsive(!openMenuResponsive)}}>
        <div className='c_interno_menu_responsive' onClick={(e)=>{e.stopPropagation()}}>
            <div className='c_menu_items_responsive'>
        {menuItems}
        </div>
        </div>
    </div>
    </>)
}