import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';

import { getSlider, tablaSlider } from '../../querys/Slider/metodos';
import { addSlider, updateSlider } from '../../rest/slider';
import { deleteSlider, updateSliderEstatus } from '../../mutations/Slider/metodos';
import { actualizaArchivo } from '../../helpers/serverStorage';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar, handleErrorGeneric } from '../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../_layout/helpers/colores';

import { rgxUrlValidacion } from '../../helpers/regexp';

import { urlServidor } from '../../helpers';
import { StarRateRounded } from '@material-ui/icons';

const { trim } = Validaciones;

function Slider() {


    const { token } = useSelector(state => state.login);

    const cleanState = { sliderID: null, imagen: null, titulo: "", subtitulo: "", texto: "", textoEnlace: "", enlace: "", fechaCreacion: "", estatus: true, urlImagen:"" };
    const cleanErrorState = {
        imagen: { error: false, helperText: "" },
        titulo: { error: false, helperText: "" },
        subtitulo: { error: false, helperText: "" },
        texto: { error: false, helperText: "" },
        textoEnlace: { error: false, helperText: "" },
        enlace: { error: false, helperText: "" },
        fechaCreacion: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        ruta: {error: false, helperText: ""}


    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);

    let sliderIDRef = useRef(null);
    let tituloRef = useRef(null);
    let subtituloRef = useRef(null);
    let textoRef = useRef(null);
    let textoEnlaceRef = useRef(null);
    let enlaceRef = useRef(null);
    let imagenRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "sliderID", variable: "slider" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getSlider /*setErrorToken*/
        })
    }, {
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "sliderID" }, { campo: "urlImagen" }, { campo: "titulo" }],
        disabled: { multiseleccion: true },
        onClick: ({ sliderID, urlImagen }) => handleAlertGeneric({
            setActualizar, actualizar,
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de eliminar el slider, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, eliminalo!`,
                msjCancelacion: "Mejor no, gracias"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => { ftEliminar(sliderID, urlImagen);} /*setErrorToken*/
          
        })
    }, {
        icono: RadioButtonUncheckedIcon,
        color: success,
        descripcion: "Cambiar Estatus",
        parametros: [{ campo: "estatus" }, { campo: "sliderID" }],
        disabled: { multiseleccion: true },
        onClick: ({ sliderID, estatus }) => handleAlertGeneric({
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de ${estatus ? "desactivar" : "activar"} el estatus del slider, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, ${estatus ? "desactivalo" : "activalo"}!`,
                msjCancelacion: "Mejor no, gracias"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                mensajes: {
                    msjEspera: { descripcion: "Espere unos momentos estamos a punto de cambiar el estatus" },
                    msjCorrecto: {
                        title: "¡Se cambio el estatus!",
                        descripcion: `El estatus se cambio exitosamente a ${estatus ? "desactivado" : "activado"}`,
                        msjConfirmacion: "Gracias"
                    }
                },
                parametros: { input: { sliderID, estatus: !estatus } }, asyncMutation: updateSliderEstatus /*setErrorToken*/
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "sliderID",
        color: primary,
        title: "Slider",
        iconTable: <ViewCarouselIcon />,
        headers: [
            { variable: "sliderID", descripcion: "ID" },
            { variable: "imagen", descripcion: "Imagen" },
            { variable: "urlImagen", descripcion: "urlImagen", hide:true},
            { variable: "titulo", descripcion: "Título del Slider" },
            { variable: "fechaCreacion", descripcion: "Fecha de Creación" },
            { variable: "estatus", descripcion: "Estatus" },
        ],
        responsiveTitle: ["titulo"],
        filter: [

            { campo: "titulo" }
        ],
        selectFilter: [
            {
                campo: "estatus", placeholder: "Seleccionar estado del slider", retorna: "boolean",
                data: [
                    { value: null, label: "Mostrar todos" },
                    { value: "true", label: "Activos" },
                    { value: "false", label: "Inactivos" }
                ]
            }
        ],

        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["estatus"], tipo: "estatus" },
        { columnas: ["imagen"], tipo: "imagen", url: '', token },
        { columnas: ["fechaCreacion"], tipo: "fecha" }],
    }

    const inputs = [{
        disabled,
        id: "imagen",
        value: state.urlImagen,
        title: "Portada del slider*",
        tipo: "imageupload",
        onChange: handleSetImagen,
        removeImage: state.imagen
    },
    {
        disabled,
        id: "titulo",
        value: state.titulo,
        error: errorState.titulo.error,
        success: state.titulo && !errorState.titulo.error ? true : undefined,
        helperText: errorState.titulo.helperText,
        inputRef: tituloRef,
        title: "Título del slider*",
        placeholder: "Capture el título del slider",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "titulo"),
        onKeyDown: (evt) => handleFocus(evt, tituloRef)
    },
    {
        disabled,
        id: "subtitulo",
        value: state.subtitulo,
        error: errorState.subtitulo.error,
        success: state.subtitulo && !errorState.subtitulo.error ? true : undefined,
        helperText: errorState.subtitulo.helperText,
        inputRef: subtituloRef,
        title: "Subtítulo",
        placeholder: "Capture el subtítulo",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "subtitulo"),
        onKeyDown: (evt) => handleFocus(evt, subtituloRef)
    },
    {
        disabled,
        id: "texto",
        value: state.texto,
        error: errorState.texto.error,
        success: state.texto && !errorState.texto.error ? true : undefined,
        helperText: errorState.texto.helperText,
        inputRef: textoRef,
        title: "Texto",
        placeholder: "Capture el texto",
        onChange: ({ target: { value } }) => handleChange(value, "texto"),
        onKeyDown: (evt) => handleFocus(evt, textoRef)
    },
    {
        disabled,
        id: "textoEnlace",
        value: state.textoEnlace,
        error: errorState.textoEnlace.error,
        success: state.textoEnlace && !errorState.textoEnlace.error ? true : undefined,
        helperText: errorState.textoEnlace.helperText,
        inputRef: textoEnlaceRef,
        title: "Texto Enlace",
        placeholder: "Capture el texto del enlace",
        grid: { md: 4, lg: 4 },
        onChange: ({ target: { value } }) => handleChange(value, "textoEnlace"),
        onKeyDown: (evt) => handleFocus(evt, textoEnlaceRef)
    },
    {
        disabled,
        id: "enlace",
        value: state.enlace,
        error: errorState.enlace.error,
        success: state.enlace && !errorState.enlace.error ? true : undefined,
        helperText: errorState.enlace.helperText,
        inputRef: enlaceRef,
        title: "Enlace",
        placeholder: "Capture el enlace",
        grid: { md: 4, lg: 4 },
        onChange: ({ target: { value } }) => handleChange(value, "enlace"),
        onKeyDown: (evt) => handleFocus(evt, enlaceRef)
    },
    {
        disabled,
        id: "estatus",
        value: state.estatus,
        checked: state.estatus,
        error: errorState.estatus.error,
        success: state.estatus && !errorState.estatus.error ? true : undefined,
        helperText: errorState.estatus.helperText,
        title: "Estatus ",
        placeholder: "Seleccionar el estatus",
        tipo: "switch",
        data: seleccionables.estatus,
        grid: { md: 6, lg: 5 },
        onChange: () => setState(state => ({ ...state, estatus: !state.estatus }))
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: sliderIDRef,
            mensajeCorrecto: `El slider se ${state.sliderID ? "modifico" : "agrego"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ sliderID, imagen, titulo, enlace }) {
        let error;     
        if (imagen ==  null ){
            handleErrorGeneric({
                condicion: !imagen,
                mensaje: "Para que funcionemos bien, necesitamos que coloque una imagen"
            });
            return;
        }       
        let validaciones = [      
        {
            condicion: !titulo || trim(titulo) === "",
            ref: tituloRef, keyError: "titulo",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un título al post"
        }, {
            condicion: (enlace && trim(enlace) !== "") && !rgxUrlValidacion.test(enlace), ref: enlaceRef, keyError: "enlace",
            mensajeError: "Para que podamos funcionar bien necesitamos que se defina el enlace correctamente"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });
        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { sliderID, imagen, titulo, subtitulo, texto, textoEnlace, enlace, fechaCreacion, estatus } = state;
            let ruta = state.sliderID > 0 ? `public/tonline${state.urlFoto}` : 'slider';
            let remover = imagen ? false : true;

            let error = await handleValidaciones({ sliderID, imagen, titulo, subtitulo, texto, textoEnlace, enlace, fechaCreacion, estatus });
            if (error) return error;

            let result = await actualizaArchivo(ruta, imagen, 0, sliderID, null, remover)      
            if (result ==  '' ){
                handleErrorGeneric({
                    condicion: result == '',
                    mensaje: "No se pudo actualizar la imagen"
                });
                return;
            }        
            if (sliderID) await updateSlider({
                data:
                    { sliderID, imagen:result, titulo, subtitulo, texto, textoEnlace, enlace, fechaCreacion, estatus,dominio: window.location.hostname.split('.')[0] }, token
            });
            else await addSlider({
                data:
                    { sliderID, imagen:result, titulo, subtitulo, texto, textoEnlace, enlace, fechaCreacion, 
                        estatus,dominio: window.location.hostname.split('.')[0] }, token
            });
           
        }
        return ftGuardar();
    }
    function ftEliminar(sliderID, urlImagen) {
        async function ftEliminar() {        
            try {
                let ruta = sliderID > 0 ? `public/tonline${urlImagen}` : '';
                let result = true;
                if (urlImagen !== '') {
                   result = await actualizaArchivo(ruta, null, 0, sliderID, null, true) 
                }        
                if (result ==  false ){
                    handleErrorGeneric({
                    condicion: result == '',
                    mensaje: "No se pudo eliminar la imagen"
                    });
                    return;
                }       
                await deleteSlider( {sliderID: sliderID}, token)    
                setActualizar(!actualizar)                    
                setAlert({
                    descripcion: `Se eliminó el slider`,
                    title: "¡Slider eliminado!",
                    tipo: success,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });        

            } catch ({ message }) {
                setAlert({
                    descripcion: `No se pudo eliminar el slider: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }            
        }
        return ftEliminar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleSetImagen(imagen) { setState({ ...state, imagen, urlImagen:null }); } //, urlImagen: null



    function ftEffect() {
        async function effect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);
   
    return (token ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSlider}
            token={token}
        />
        <FormularioModal
            open={open}
            title={`${state.sliderID ? "Modificar" : "Agregar"} slider`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Slider;


