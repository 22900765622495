
import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "_layout/genericComponents/Metodos";

import { urlServidor } from '../../../helpers';
import { GET_DEPARTAMENTO, TABLA_DEPARTAMENTO, GET_GRUPOERP,GET_ALL_GRUPOERP, GET_DEPARTAMENTOGRUPO, GET_ALLDEPARTAMENTOGRUPO,GET_SUBGRUPOERP,GET_ALL_SUBGRUPOERP } from '../index';

export const getDepartamento = async (variables, token) => {
    try {
        let filtro = {...variables, dominio:window.location.hostname.split('.')[0]};
        let { getDepartamento } = await FetchAPI.graphql({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_DEPARTAMENTO
        });

        return getDepartamento;

    } catch({message}) { throw new Error(message); }
}

export const tablaDepartamento = async (variables, token) => {
    try {
        let filtro = {...variables, dominio:window.location.hostname.split('.')[0]};
        let { filterDepartamento } = await FetchAPI.graphql({
            token,
            variables: filtro ,
            url: urlServidor,
            query: TABLA_DEPARTAMENTO
        });

        return filterDepartamento;

    } catch({message}) { throw new Error(message); }
}


export const getGrupoERP = async (variables,token) => {
    try {
        let filtro = {...variables, dominio:window.location.hostname.split('.')[0]};

        let {getGrupoERP} = await FetchAPI.graphql ({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_GRUPOERP
        });

        return getGrupoERP;
    } catch({message}) {throw new Error(message);}

}

export const getAllGrupoERP = async (variables,token) => {
    try {
        let filtro = { ...variables, dominio:window.location.hostname.split('.')[0]};
        let {getAllGrupoERP} = await FetchAPI.graphql ({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_ALL_GRUPOERP
        });

        return getAllGrupoERP;
    } catch({message}) {throw new Error(message);}

}

export const selectGrupoERP  = async (variables, token) => {
    try {

        let grupos = await getAllGrupoERP(variables, token);

        if(!Array.isArray(grupos) || grupos.length === 0) throw new Error("No se encontraron resultados")

        return grupos.map(({ grupoID:value, grupo:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const getSubGrupoERP = async (variables,token) => {
    try {
        let filtro = {...variables, dominio:window.location.hostname.split('.')[0]};

        let {getSubGrupoERP} = await FetchAPI.graphql ({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_SUBGRUPOERP
        });

        return getSubGrupoERP;
    } catch({message}) {throw new Error(message);}

}

export const getAllSubGrupoERP = async (variables,token) => {
    try {
        let filtro = { ...variables, dominio:window.location.hostname.split('.')[0]};

        let {getAllSubGrupoERP} = await FetchAPI.graphql ({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_ALL_SUBGRUPOERP
        });

        return getAllSubGrupoERP;
    } catch({message}) {throw new Error(message);}

}


export const selectSubGrupoERP  = async (variables, token) => {
    try {

        let subgrupos = await getAllSubGrupoERP(variables, token);

        if(!Array.isArray(subgrupos) || subgrupos.length === 0) throw new Error("No se encontraron resultados");

        return subgrupos.map(({ subgrupoID:value, subgrupo:label }) => ({ value, label }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const getDepartamentoGrupo = async (variables, token) => {
    try {
        let filtro = {...variables, dominio:window.location.hostname.split('.')[0]};
        let { getDepartamentoGrupo } = await FetchAPI.graphql({
            token,
            variables:{ filtro },
            url: urlServidor,
            query: GET_DEPARTAMENTOGRUPO
        });

        return getDepartamentoGrupo;

    } catch({message}) { throw new Error(message); }
}

export const getAllGrupoRelacionERP = async (variables) => {
    try {

        let {getAllDepartamentoGrupo} = await FetchAPI.graphql ({
            variables,
            url: urlServidor,
            query: GET_ALLDEPARTAMENTOGRUPO
        });

        return getAllDepartamentoGrupo;

    } catch({message}) { throw new Error(message); }
}

export const selectGrupoRelacionERP  = async (variables) => {
    try {

        let grupos = await getAllGrupoRelacionERP(variables);

        if(!Array.isArray(grupos) || grupos.length === 0) throw new Error("No se encontraron resultados");
        return grupos.map(({ departamentoID:value, departamento:label,grupoID,grupoErpID    }) => ({
            value, label, grupoID, grupoErpID
        }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}
