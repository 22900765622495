import React, { useState, useEffect } from 'react';
import './styles.css'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import {  Encriptar } from '@renedelangel/helpers';
import { makeStyles } from '@material-ui/core/styles';

// Redux
import { useDispatch,useSelector } from 'react-redux';
import { eliminarDelCarritoRedux, modificarProductoRedux } from '../../redux/actions/carritoActions';

// hooks
import { useTitle } from '../../hooks/title';
/* import Login from '../Login'; */
import Login from 'componentes/LoginB';
/* import Modal from '../../_layout/genericComponents/Modal'; */
import Modal from '../../_layout/NuevosComponentes/Modal';
import Registro from 'componentes/Registro';
import Lista from '../../_layout/genericComponents/Lista';
import {redondeo} from '../../helpers/regexp.js';
import { setLocalStorage,  getInitialStorage, cleanLocalStorageItem } from '../../_layout/helpers/localstorage';

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { info, primary, success, danger } from '../../_layout/helpers/colores';
import AlertaGenerica from '../../_layout/genericComponents/AlertaGenerica';

// material-ui icons
import DeleteIcon from '@material-ui/icons/Delete';

import styles from "../../_layout/assets/jss/material-dashboard-pro-react/views/extendedListaStyle.js";

import { deleteCarrito, upsertCarrit} from "../../querys/Carrito/index";
import { tablaPaqueteria } from '../../querys/Paqueterias/metodos';
import { getProducto } from '../../querys/Productos/metodos';

import CarritoNuevo from '../../_layout/NuevosComponentes/CarritoNuevo';
import { toFixed } from '../../helpers/regexp.js';

import { getCarrito } from '../../querys/Carrito/index';
import { setCarrito } from '../../redux/actions/carritoActions';

const {Encrypt,Decrypt}=Encriptar;

const useStyles = makeStyles(styles);

function Carrito() {

    // useTitle(`Listado de productos - ${process.env.REACT_APP_TIENDA_NAME}`);
    const classes = useStyles();
    let imagenDefault =  '/imagenes/cajaAbierta.jpg';
    let seed = "softwone";

    const { usuario, token } = useSelector(state => state.login);
    const { nombre, perfilID, usuarioID} = usuario ? usuario : {};
    const { carrito } = useSelector(state => state.carrito);
    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    const dispatch = useDispatch();
    const eliminarItemRedux = (item) => dispatch(eliminarDelCarritoRedux(item));
    const modificarItemRedux = (item) => dispatch(modificarProductoRedux(item));
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));

    let subtotalPedido = 0;
    let envio =  0;
    let costoPaqueteria = 0;
    let totalPedido = 0;

    let columns  = [];
    let rows = [];

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const acciones = [{
        icono:   <DeleteIcon />,
        descripcion: "Eliminar del carrito",
        parametros: [{ campo: "productoID", variable: "productoID"}, { campo: "usuarioID", variable:"usuarioID" }],
        onClick: (parametros) =>  eliminardelCarrito(parametros)
    }];

    const noTruncarDecimales = {maximumFractionDigits: 2,  minimumFractionDigits: 2/* , style:"currency", currency: 'MXN' */};

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const paqueteriaInicial = {
        costo: 0,
        descripcion: "",
        estatus: true,
        paqueteria: "Ninguna",
        paqueteriaID: null,
        predeterminada: true,
        ventaMinima: 100
    }

    const [alert, setAlert] = useState(null);
    const [redirigir, setRedirigir] = useState(null);
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [iniciar, setIniciar] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [articulos, setArticulos] = useState(carrito);
    const [envioPaqueteria, setEnvioPaqueteria] = useState(paqueteriaInicial);
    const [metodoEntrega, setMetodoEntrega] = useState({"aDomicilio": true, "enTienda":false, "envio": 0});
    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);

    const [totales, setTotales] = useState({"subtotal":0, "envio": 0, "total": 0});

    function eliminardelCarrito(parametros){

        let articulo = parametros.productoID;
        if (usuario !== null)
        {
            ftEliminarItem(parametros.carritoID);
        }
        eliminarItemRedux(articulo);
        actualizaTotales();
    }
    async function actualizarCantidad(parametros, valor){

        let index = carrito.map(item => item.productoID).indexOf(Number(parametros.productoID));
        let cant =  Number(carrito[index].cantidad) + Number(valor);

         cant = cant <= 1000 ? cant : 0;
        if (index > -1){
            if(cant > 0)
            {

                if(usuario !== null){
                    ftAgregarItem(parametros.productoID, cant)
                }
                let item = carrito[index];
                cant = await ftValidaExistencia(parametros.productoID, cant)
                let nuevo = {...item, cantidad: cant}

                modificarItemRedux(nuevo);
                actualizaTotales();
            }
        }
    }
    async function modificaCantidad(cant, index, productoID){
        if (cant) {
            let cantidad = cant.target.value;
       
            if(usuario !== null){
                ftAgregarItem(productoID, cantidad);
            }
            let item = carrito[index];
            cantidad = await ftValidaExistencia(productoID, cantidad)
            let nuevo = {...item, cantidad: cantidad}

            modificarItemRedux(nuevo);
            actualizaTotales();
        }
    }

    function replaceAll( text, busca, reemplaza ){
    while (text.toString().indexOf(busca) != -1)
        text = text.toString().replace(busca,reemplaza);
    return text;
    }
    function getRederigir(url = "/") {
        return (<><Switch><Redirect from="*" to={'/pedido'} /></Switch></>);
    }
    async function continuarCompra(){

        let subtotalCarrito = replaceAll(totales.subtotal, ",", "" );

        if(Number(subtotalCarrito) > 0){
            if(!usuario){
               setOpenOpciones(openOpciones => ({
                        ...openOpciones,
                        open: !openOpciones.open,
                        formulario: "login",
                        descripcion: "Iniciar sesión"
                }));
            }else{
                for (let i=0; i< carrito.length; i++)
                {
                    let productoID = carrito[i].productoID;
                    let producto = await getProducto({ productoID: productoID}, token);
                    let existencia = producto.existencia;
                    let vtaBajoCero = producto.vntaExistCero;
                    let precio = producto.precioVentaOnline;            

                    if(vtaBajoCero ==  0){
                        if(existencia < carrito[i].cantidad ){
                           eliminardelCarrito({"productoID":  carrito[i].productoID, "carritoID":  carrito[i].carritoID});
                        }
                    }
                    if(carrito[i].precioVentaOnline !== precio){
                        carrito[i].precioVentaOnline = precio;
                    }
                }
                actualizaTotales();
                setRedirigir(getRederigir("/pedido"))            }
        }else{
            setAlert({
                    descripcion: `Su monto es de 0, por favor agregue productos a su carrito`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
            });
        }

    }
    function actualizaMetodo(datos){
        setMetodoEntrega(datos);
        setTotales(totales => ({...totales,envio:datos.envio}));
    }
    function ftAgregarItem(productoID, cantidad) {
        async function ftAgregarItem() {

        let carritoSelec = await upsertCarrito({
            input: {productoID: Number(productoID), cantidad: Number(cantidad)}
        }, token);
            return carritoSelec;
        }
        return ftAgregarItem();
    }

    function ftEliminarItem(carritoID) {
        async function eliminarItem() {

        let carritoSelec = await deleteCarrito({
            carritoID:  Number(carritoID)
        }, token);
            return carritoSelec;
        }
        return eliminarItem();
    }
    function actualizaTotales(){
        subtotalPedido = 0.00;
        envio=0;
        carrito.map((item) => {
        subtotalPedido = subtotalPedido + (Number(item.cantidad) * Number(parseFloat(toFixed(item.precioVentaOnline, 2))));
        });
        subtotalPedido = redondeo(subtotalPedido, 2);
        subtotalPedido = subtotalPedido.toLocaleString('en-US', noTruncarDecimales);
        setTotales(totales => ({...totales, subtotal: subtotalPedido}));
    }

    function ftConsultaExistencias(){
        async function consultaExistencias(){
            let i=0;
            if (carrito) {
                for (i=0; i< carrito.length; i++)
                {
                    let productoID = carrito[i].productoID;
                    let producto = await getProducto({ productoID: productoID}, token);
                    let existencia = producto.existencia;
                    let vtaBajoCero = producto.vntaExistCero;

                    if(vtaBajoCero ==  0){
                        if(existencia < carrito[i].cantidad ){
                        eliminardelCarrito({"productoID":  carrito[i].productoID, "carritoID":  carrito[i].carritoID});
                        }
                    }
                }
            }

        }
        return consultaExistencias();
    }
    function ftValidaExistencia(productoID, cantidad){
        async function validaExistencia(){

            let cant = cantidad;
            let producto = await getProducto({ productoID: productoID}, token);
            let existencia = producto.existencia;
            let vtaBajoCero = producto.vntaExistCero;

            if(vtaBajoCero ==  0){
                if(existencia < cantidad ){
                    cant  = existencia;
                    setAlertGenerica({
                        visible:true,
                        descripcion: `La cantidad ${cantidad} sobrepasa la existencia disponible (${existencia})`,
                        tipo: "error",
                    });
                }
            }
            return cant;
        }
        return validaExistencia();
    }
    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlertGenerica({
            ...alertGenerica,
            visible:false
        });
    };

    useEffect(() => {
        async function ftEffect() {
            try {
                actualizaTotales();
            }
            catch({message}) {
                console.log('error', message)
            }
        } ftEffect();
    }, [carrito]);

    useEffect(() => {
        async function ftEffect() {
            try {
                ftConsultaExistencias();
                actualizaTotales();
            }
            catch({message}) {
                console.log('error', message)
            }
        } ftEffect();
    }, []);


    useEffect(() => {
        if(usuario && token){
        async function ftActualizarCarrito(){
            let carritoSelec = await getCarrito({
                usuarioID: Number(usuario.usuarioID)
            }, token);
            setCarritoRedux(carritoSelec.listado);
        }ftActualizarCarrito();
    }
}, [actualizar])
  

    return (<div style={{backgroundColor: '#ffff'}}>
        { redirigir }     

        <CarritoNuevo 
        data = {carrito}
        actualizar = {actualizar}
        setActualizar = {setActualizar}
        eliminarCarrito={eliminardelCarrito}
        actualizarCantidad={actualizarCantidad}
        modificaCantidad={modificaCantidad}
        grabarPedido={continuarCompra}
        metodoEntrega={metodoEntrega}
        actualizaMetodo={actualizaMetodo}
        usuario={usuario}
        token={token}
        />

       {/* { <Lista
            datos={carrito}     
            acciones={acciones}
            actualizar={actualizar}
            //paqueteria={envioPaqueteria}
            eliminarCarrito={eliminardelCarrito}
            actualizarCantidad={actualizarCantidad}
            modificaCantidad={modificaCantidad}
            grabarPedido={continuarCompra}
            metodoEntrega={metodoEntrega}
            actualizaMetodo={actualizaMetodo}
        ></Lista> }  */}
        {/* {openOpciones && <Modal
            title={openOpciones.descripcion}
            open={openOpciones.open}
            onClose={() => setOpenOpciones(cleanOpenOpciones)}
        >
        <Login onClose={() => setOpenOpciones(cleanOpenOpciones)} />
        </Modal>     
        }  */}   

        {(openOpciones.open && openOpciones.formulario === "login") &&
            <Modal
                claseAux="cIniciaSesion"
                titulo="INICIA SESIÓN"
                cModalChico={true}
                title={openOpciones.descripcion}
                open={openOpciones.open}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Login onClose={() => setOpenOpciones(cleanOpenOpciones)}
                    abrirRegistro={() => setOpenOpciones({ ...openOpciones, formulario: "registro", open: true })} />
            </Modal>
        }   
        {(openOpciones.open && openOpciones.formulario === "registro") &&
            <Modal
                claseAux="cRegistro"
                titulo={"CREAR UNA CUENTA"}
                cModalChico={true}
                open={openOpciones.open}
                onClose={() => setOpenOpciones(cleanOpenOpciones)}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Registro onClose={() => setOpenOpciones(cleanOpenOpciones)} />
            </Modal>
        }
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
        { alertGenerica && <AlertaGenerica
            visible={alertGenerica.visible}
            descripcion={alertGenerica.descripcion}
            tipo={alertGenerica.tipo}
            handleClose={handleCloseMensaje}
        /> }
    </div>);

}

export default Carrito;
