import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

// Generic components
import Preloader from '../../../_layout/genericComponents/Preloader';
import Notifications from '../../../_layout/genericComponents/Notificaciones';

import GridContainer from "../../../_layout/components/Grid/GridContainer.js";
import GridItem from "../../../_layout/components/Grid/GridItem.js";

// material-ui icons
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { danger, success } from '../../../_layout/helpers/colores';

import { isToken } from '../../../querys/Login/metodos';
import { updateEstatus } from '../../../mutations/Login/metodos';

import errorImage from '../../../_layout/assets/svg/undraw_cancel_u1it.svg';
import successImage from '../../../_layout/assets/svg/confirmed.png'; //undraw_High_five_u364.svg
import './estilos.css';

function ActivarCuenta() {

    const { token } = useParams();

    const cleanState = { ok: false, error: false, message: "", icon: null, color: null };
    const errorInfo = {
        ok: true,
        title: "¡Ocurrió un problema!",
        message: "Lamentablemente el enlace para activar su cuenta ya ha caducado",
        icon: ErrorIcon,
        color: danger,
        image: errorImage,
        error: true
    };
    const successInfo = {
        ok: true,
        title: "¡ACTIVACIÓN EXITOSA!",
        message: "Su cuenta de correo se activo correctamente, ya puede iniciar sesión",
        icon: CheckCircleOutlineIcon,
        color: success,
        image: successImage,
        error: false
    };

    const [state, setState] = useState(cleanState);

    const grid = { xs: 12, sm: 12, md: 12, lg: 12 };

    function ftEffect() {
        async function effect() {
            try {
                if(!token) return;
                let hasToken = await isToken(token);
                if(!!!hasToken) setState(errorInfo);
                let ok = await updateEstatus({ estatus: true }, token);
                if(!ok) setState(errorInfo);
                setState(successInfo);
            } catch({message}) {
                console.error(message);
                setState(errorInfo);
            }
        } effect();
    }

    useEffect(ftEffect, [token]);

    /* <Notifications
                    message={state.message}
                    icon={state.icon}
                    color={state.color}
                    open={state.ok}
                />*/

    return (state.ok ? <div className='pagePrincipal'>
        <GridContainer
            direction="column"
            justify="center"
            alignItems="center"
        >            
            <GridItem { ...grid }>
                {
                    state.error ? 
                    <Notifications
                        message={state.message}
                        icon={state.icon}
                        color={state.color}
                        open={state.ok}
                    />
                    : 
                    <div className='tituloCorrecto'>{state.title}</div>
                }
            </GridItem>
            <GridItem { ...grid }>
               
            </GridItem>
            <GridItem { ...grid }>
                <div className="imagenCentro">
                <img src={state.image} style={{ maxHeight: "500px", width: "100%", height: "auto"  }} alt={state.message} />
                </div>
            </GridItem>
           
        </GridContainer>  </div>: <Preloader />);

}

export default ActivarCuenta;
