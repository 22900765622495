import React, { useState, useEffect, useRef } from 'react';
import './estilos.css';
import logoDefault from '../../assets/softwone.svg';
import logoFB from './logoFB.png';
import logoTW from './logoTW.png';
import icoProd from './icoProd.png';
import { selectGrupoERP, selectSubGrupoERP, getAllGrupoERP, getAllSubGrupoERP } from "../../querys/Departamentos/metodos";
import { useSelector } from 'react-redux';
import { ELIMINAR_PRODUCTO_CARRITO } from 'redux/types';
import { Encriptar } from '@renedelangel/helpers';
import { Link } from 'react-router-dom';
const { Encrypt } = Encriptar;

export function LogosRedesSociales(rutaFB, rutaTW) {
    rutaFB = "fb";
    rutaTW = "tw";
    if (rutaFB || rutaTW) {
        return (<div className="RowHeader socialMedia" key="headerSocial">
            {rutaFB ? <div className="logoSocial" key="logoSFB">
                <img className="imgLogoSocial" src={logoFB} title="facebook" key="logoFB" />
            </div> : ""}
            {rutaTW ? <div className="logoSocial" key="logoSTW">
                <img className="imgLogoSocial" src={logoTW} title="twitter" key="logoTW" />
            </div> : ""}
        </div>);
    }
    return (<></>);
}

export function MenuLineal(redirigir, paginaPrincipal, menus = [], titulo, logo) {

    let tokenFijo = 'c29mdHdvbmUyMDIwQCM=';
    const seed = 'softwone';
    const [itemClass, setitemClass] = useState("menuItem");
    const [menuProductos, setMenuProductos] = useState([]);
    const [showGrupos, setShowGrupos] = useState(false);
    const [showSubgrupos, setShowSubgrupos] = useState(false);
    const myRef = useRef(null);
    if (titulo) {
        document.title = titulo;
    }

    const handleClickOutside = e => {
        if (myRef) {
            if (!myRef.current.contains(e.target)) {
                MostrarMenu(true, paginaPrincipal);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        async function ftObtenerGruposSubgrupos() {
            let nGrupos = await getAllGrupoERP({}, tokenFijo);
            let nSubgrupos = await getAllSubGrupoERP({}, tokenFijo);

            if (nGrupos.length > 0) {
                setMenuProductos(
                    nGrupos.map(element => {
                        return {
                            grupo: element.grupo,
                            grupoID: element.grupoID,
                            subgrupos:
                            
                                nSubgrupos.map(el => {
                                    if (el.grupoID == element.grupoID) {
                                    return el
                                    }
                                })
                        }
                    }));
            }

        }
        ftObtenerGruposSubgrupos()
    }, [])

    function Menu(paginaPrincipal) {
        //De momento fijos, pero se podrían recibir


        menus = [
            /* { key: "Inicio", titulo: "Inicio", ruta: `/${paginaPrincipal}`, iconoMenu: null },
            { key: "Productos", titulo: "Productos", ruta: "", iconoMenu: icoProd },
            { key: "Nosotros", titulo: "Nosotros", ruta: "/Nosotros", iconoMenu: null },
            { key: "Contacto", titulo: "Contacto", ruta: "/Contacto", iconoMenu: null } */
        ];

        let menuItems = [];
        for (let i = 0; i < menus.length; i++) {
            const element = menus[i];
            if (i === 0) {
                menuItems.push(itemMenu(element.key, element.titulo, element.ruta, menus.length + " cItemInicial", element.iconoMenu));
            } else {
                menuItems.push(itemMenu(element.key, element.titulo, element.ruta, menus.length, element.iconoMenu));
            }
        }

        return (<div className="RowHeader" key="headerMenu">
            <div key="btnMenu" id="btnMenu" className="menuItem oculto" onClick={MostrarMenu}>
                ☰ Menú
            </div>
            <div className="menu" key="Menu">
                {menuItems}
            </div>
        </div>
        );
    }

    function itemMenu(key, titulo, ruta, d, iconoMenu) {

        if (key == 'Productos') {
            return (
            <div className={itemClass + " d" + d} key={key} /* onClick={() => redirigir(ruta)} */ onMouseOver = {() => {setShowGrupos(true)}} onMouseLeave = {() => {setShowGrupos(false)}}>
                <div className={"tituloMenu"} >
                    {titulo}
                </div>
                {iconoMenu ? <img className="iconoMenu" src={iconoMenu} /> : <></>}

                {
                            <div className='c_menu_productos' style={{display: showGrupos ? 'inline-block' : 'none'}} >
                                {
                                    menuProductos.map(element => {
                                        return (
                                            <Link to={{
                                                pathname: "/productos",
                                                state: { grupoParam: element.grupoID }
                                                }} onClick={() => {setShowGrupos(false)}}>  
                                            <div className='i_menu_productos_grupos'>
                                            
                                    <h6> {element.grupo} </h6>  
                                        <div className='c_menu_productos_subgrupos' onClick = {e => e.preventDefault()} >
                                            {
                                                element.subgrupos?.map( el => {
                                                    if(el !== undefined){
                                                   return (<div className='i_menu_productos_subgrupos'
                                                   onClick={() => {redirigir(`/products/${handleViewProducto(el.subgrupoID)}`); setShowGrupos(false)}}
                                                   /* onClick={() => {console.log(`${el.subgrupoID}`)}} */
                                                    >
                                                        <h6>{el?.subgrupo}
                                                        </h6>
                                                </div>)}
                                                } )
                                            }
                                        </div>
                                    </div>
                                    </Link>
                                        )
                                    })
                                }
                            </div>
                 
                }
            </div>);
        }

        function handleViewProducto(id){
            let parametros = `{"id": "${id}"}`;
            let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&");
            return url;
        }

        return (<div className={itemClass + " d" + d} key={key} onClick={() => redirigir(ruta)}>
            <div className={"tituloMenu"}>
                {titulo}
            </div>
            {iconoMenu ? <img className="iconoMenu" src={iconoMenu} /> : <></>}
        </div>);
    }

    function MostrarMenu(ocultar = false) {
        if (itemClass === "menuItem") {
            setitemClass("menuItem visible");
        } else {
            setitemClass("menuItem");
        }

        if (ocultar === true) {
            setitemClass("menuItem");
        }
    }

    return (<>
        <div key="contenedorHeader" className="contenedorHeader noselect" ref={myRef}>
            {Menu(paginaPrincipal)}
        </div>
    </>);
};

export default { MenuLineal, LogosRedesSociales };
