import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "_layout/genericComponents/Metodos";

import { urlServidor, strUrlSArchivos } from '../../../helpers';
import { GET_DESEO, TABLA_DESEO  } from '../index';
import noImage from '../../../_layout/assets/svg/undraw_blank_canvas_3rbb.svg';

export const getDeseo = async (variables, token) => {
    try {
        let { getDeseo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_DESEO
        });

        return getDeseo;

    } catch({message}) { throw new Error(message); }
}

export const tablaDeseo = async (variables, token) => {
    try {
        let { filterDeseo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DESEO
        });
        let listado = filterDeseo.listado;
        let listado2 = listado.map(({ imagenPortada,  ...rest }) => {
                let imagen =   imagenPortada ? `${strUrlSArchivos}${'/public/tonline'}${imagenPortada}` : noImage;
                return {
                    ...rest,
                    imagenPortada: imagen
                }
            })
        filterDeseo.listado = listado2;
        return filterDeseo;

    } catch({message}) { throw new Error(message); }
}
