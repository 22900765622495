import React, { useState, useRef/*, useEffect */} from 'react'

// generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Formulario from '../../_layout/genericComponents/Formulario';
import Modal from '../../_layout/genericComponents/Modal';

//componentes
import Registro from '../Registro';

// Redux
import { useDispatch/*, useSelector*/ } from 'react-redux';
import { setToken, setUsuario } from '../../redux/actions/loginAction';
import { setCarrito }  from '../../redux/actions/carritoActions';

// material-ui icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SendIcon from '@material-ui/icons/Send';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { seedBackend } from '../../helpers';
import { asyncHandleFocus/*, handleFocus*/ } from '../../_layout/helpers/handles';
import { info, primary, success, danger } from '../../_layout/helpers/colores';
import { setLocalStorage,  getInitialStorage, cleanLocalStorageItem } from '../../_layout/helpers/localstorage';
import { Encriptar } from '@renedelangel/helpers';

import { getLogin, getNewPassword } from '../../querys/Login/metodos';
import { getCarrito } from '../../querys/Carrito/index'
import confirmarPedidoStyle from '_layout/assets/cssComponentes/confirmarPedido';

const { DescriptarToken } = Encriptar;

function Login({onClose}) {

    // const { usuario } = useSelector(state => state.login);

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = (usuario) => dispatch(setUsuario(usuario));
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));

    const cleanSesion = { username: "", password: "" };
    const cleanLoader = { acceder: false, cambiarPassword: false, registro:false };
    const cleanErrorState = {
        username: { error: false, helperText: ""  },
        password: { error: false, helperText: ""  }
    };
    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };

    const [sesion, setSesion] = useState(cleanSesion);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(cleanLoader);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);

    const grid = { xs:12, sm: 12, md: 5, lg: 4 };
    let seed = "softwone";

    let usernameRef = useRef(null);
    let passwordRef = useRef(null);
    let accederRef = useRef(null);

    const inputs = [{
        disabled: disabled,
        id: "username",
        value: sesion.username,
        error: errorState.username.error,
        success: sesion.username && !errorState.username.error ? true : undefined,
        helperText: errorState.username.helperText,
        title: "Correo de usuario",
        placeholder: "Capture su correo de usuario",
        icono: <EmailIcon />,
        inputRef: usernameRef,
        grid: { md: 12, lg: 12 },
        onChange: ({target:{value}}) => handleSesion(value, "username"),
        onKeyDown: (evt) => asyncHandleFocus(evt, passwordRef)
    }, {
        disabled: disabled,
        id: "password",
        value: sesion.password,
        error: errorState.password.error,
        success: sesion.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        title: "Contraseña",
        placeholder: "Capture su contraseña",
        icono: <VpnKeyIcon />,
        inputProps: { type: "password" },
        inputRef: passwordRef,
        grid: { md: 12, lg: 12 },
        onChange: ({target:{value}}) => handleSesion(value, "password"),
        onKeyDown: (evt) => asyncHandleFocus(evt, accederRef, false, ftVerificarLogin)
    }];

    const acciones = [{
        loader: loader.acceder,
        disabled: disabled,
        icono: ArrowRightIcon,
        color: info,
        descripcion: "Acceder",
        onClick: ftVerificarLogin,
        inputRef: accederRef
    },{
        loader: loader.cambiarPassword,
        disabled: sesion.username === "",
        icono: SendIcon,
        color: primary,
        descripcion: "Recuperar contraseña",
        onClick: ftRecuperarPassword,
        // inputRef: accederRef
    },
    {
        loader: loader.registro,
        disabled: disabled,
        icono: SendIcon,
        color: success,
        descripcion: "Registrarse",
        onClick: () => {
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "registro",
                descripcion: "Registrarse"
            }));

        },
    }
    ];
    function ftGetCarrito(usuarioID, token) {
        async function ftGetCarrito() {
        
        let carritoSelec = await getCarrito({
            usuarioID:  Number(usuarioID)
        }, token);

        setCarritoRedux(carritoSelec.listado);
        }
        return ftGetCarrito();
    }

    function ftVerificarLogin() {
        async function verificarLogin() {
            setLoader({ ...loader, acceder: true });
            setDisabled(true);
            try {
                const token = await getLogin({ username: sesion.username, password: sesion.password, dominio: window.location.hostname.split('.')[0] });
                if(!token) throw new Error("No se pudo obtener el token");
                let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
                ftGetCarrito(usuario.usuarioID, token);
                setUsuarioRedux(usuario);
                setTokenRedux(token);
                setErrorState(cleanErrorState);
                onClose();


            } catch({message:helperText}) {
                setErrorState(errorState => ({
                    ...errorState,
                    username: { helperText, error: true }
                }));
                setLoader(cleanLoader);
            }
            setDisabled(false);
        } verificarLogin();
    }

    function ftRecuperarPassword() {
        async function recuperarPassword() {
            setLoader({ ...loader, cambiarPassword: true });
            setDisabled(true);
            try {

                let x = await getNewPassword({ username: sesion.username, dominio: window.location.hostname.split('.')[0], href: window.location.origin });

                setAlert({
                    descripcion: "Te hemos enviado un correo para que puedas cambiar tu contraseña, este correo solo te permite cambiar la contraseña durante los próximos 5 minutos",
                    title: "¡Revisa tu correo!",
                    tipo: success,
                    msjConfirmacion: "¡Gracias!",
                    onConfirm: () => setAlert(null)
                });

            } catch({message}) {
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
            setDisabled(false);
            setLoader(cleanLoader);
        } recuperarPassword();
    }
    function ftRegistrarse() {
        async function registrarse() {
            setLoader({ ...loader, registro: true });
            setDisabled(true);
            try {
                const token = await getLogin({ username: sesion.username, password: sesion.password });
                if(!token) throw new Error("No se pudo obtener el token");
                let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
                ftGetCarrito(usuario.usuarioID, token);
                setUsuarioRedux(usuario);
                setTokenRedux(token);
                setErrorState(cleanErrorState);
                onClose();
            } catch({message:helperText}) {
                setErrorState(errorState => ({
                    ...errorState,
                    username: { helperText, error: true }
                }));
                setLoader(cleanLoader);
            }
            setDisabled(false);
        } registrarse();
    }

    function handleSesion(value, id) {   
       // let val  = value. val.toUpperCase()
        setSesion(sesion => ({
            ...sesion,
            [id]: value
        }));
    }

    return (<>
        <Modal
            title={openOpciones.descripcion}
            open={openOpciones.open}
            onClose={() => setOpenOpciones(cleanOpenOpciones)}
        >
        <Registro onClose={() => setOpenOpciones(cleanOpenOpciones)} />
        </Modal>
        <Formulario
            header={false}
            grid={grid}
            inputs={inputs}
            acciones={acciones}
            focus={usernameRef}
            color={primary}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);

}

export default Login;
