import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {  Encriptar } from '@renedelangel/helpers';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";

// material-ui icons

import DeleteIcon from '@material-ui/icons/Delete';
import GradeIcon from '@material-ui/icons/Grade';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { tablaDeseo } from '../../querys/Deseos/metodos';
import { deleteDeseos } from '../../mutations/Deseos/metodos';

import { handleErrorInputText, handelAlertEliminar, handleEliminar, } from '../../_layout/helpers/handles';
import { danger, primary } from '../../_layout/helpers/colores';

const {Encrypt,Decrypt}=Encriptar;

function Deseos() {

    const { token, usuario } = useSelector(state => state.login);
    const {usuarioID} = usuario

    const cleanState = { deseosID: null, rutaImagen: "", };
    const cleanErrorState = {
        rutaImagen: { error: false, helperText: "" },
        descripcionCorta: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        precioNormalConIva: { error: false, helpertext: "" },
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [redirigir, setRedirigir] = useState(null);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const acciones = [{
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "deseosID" }, { campo: "rutaImagen" }, { campo: "descripcionCorta" }, { campo: "descripcion", campo: "precioNormalConIva" }],
        disabled: { multiseleccion: true },
        onClick: ({ deseosID, descripcionCorta = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `al deseo ${descripcionCorta}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { deseosID }, asyncDelete: deleteDeseos /*setErrorToken*/
            })
        })
    },
    {
        icono: VisibilityIcon,
        color: primary,
        descripcion: "Ver detalles del producto",
        parametros: [{ campo: "productoID" }],
        disabled: { multiseleccion: true },
        onClick: ({ productoID }) => redireccionar(productoID)
    }
   ];

    const infoTabla = {
        acciones,
        actualizar,
        id: "deseosID",
        color: primary,
        title: "Deseos",
        iconTable: <GradeIcon />,
        headers: [
            { variable: "deseosID", descripcion: "deseosID", hide: true},
            { variable: "productoID", descripcion: "productoID", hide: true},
            { variable: "imagenPortada", descripcion: "Imagen" },
            { variable: "descripcionCorta", descripcion: "Título" },
            { variable: "descripcion", descripcion: "Descripción", hide: true },
            { variable: "precioNormalConIva", descripcion: "Precio"},
        ],
        responsiveTitle: ["descripcionCorta", "precioNormalConIva"],
        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["imagenPortada"], tipo: "imagen", url: '', token, titulo: "descripcionCorta"  }],

    }
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }
    function getRederigir(url = "/") {
        return (<><Switch><Redirect from="/deseos" to={url} /></Switch></>);
    }
    function redireccionar(productoID){

        let seed = "softwone";
        const parametros = `{"id": "${productoID}"}`;
        let url = Encrypt({data: parametros, seed}).replace(/\//g, "&")
        setRedirigir(getRederigir(`producto/${url}`))
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function ftEffect() {
        async function effect() {
            try {



            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);



    return (token ? <>
        { redirigir }
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDeseo}
            token={token}
            parametrosFijos={{clienteID: usuarioID, count:0}}
        />
        <FormularioModal
            open={open}
            title={`imagen`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            acciones={acciones}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Deseos;
