import React, { useState, useRef, useEffect } from 'react';
import { ExitStatus } from 'typescript';
import {
    enCamino,
    entregado,
    enProceso,
    noEnviado,
    noPagado,
    pagado,
    pendProceso,
    noEntregado,
    reembolsoProceso,
    reembolsoFinalizado,
    pedCancelado,
    solicitudReembolso,
    reembolsoPendFinalizar

} from '../../_layout/assets/svg/exportsvg';
import './estilos.css';


function LineaSeguimiento(props) {

    let {datosHistoria = [], estatusActivo = 1, reembolso = 0}= props;

    let etapas = []
    let nomEstatus = 'NO PAGADO' 

    let activos = [
       // {estatus:1, imagen: noPagado,  descEstatus: 'NO PAGADO', fecha:''},
        {estatus:2, imagen: pagado,  descEstatus:'PAGADO', fecha:''},
        {estatus:3, imagen: enProceso, descEstatus: 'EN PROCESO', fecha:'' },
        {estatus:4, imagen: enCamino, descEstatus: 'EN CAMINO', fecha:'' },
        {estatus:5, imagen: entregado, descEstatus: 'ENTREGADO', fecha: '' },
        {estatus:6, imagen: pedCancelado, descEstatus: 'CANCELADO', fecha: '' },
        {estatus:7, imagen: reembolsoProceso, descEstatus: 'SOL.REEMBOLSO', fecha: '' },
        {estatus:8, imagen: reembolsoProceso, descEstatus: 'PROC. REEMBOLSO', fecha: '' },
        {estatus:9, imagen: reembolsoFinalizado, descEstatus: 'FINALIZADO', fecha: '' }
    ]
    let inactivos = [
        {estatus:1, imagen: noPagado,  descEstatus: 'NO PAGADO', fecha:''},
        {estatus:3, imagen: pendProceso,  descEstatus:'EN PROCESO', fecha:''}, // pagado
        {estatus:4, imagen: noEnviado, descEstatus: 'EN CAMINO', fecha:'' }, //en proceso
        {estatus:5, imagen: noEntregado, descEstatus: 'ENTREGADO', fecha:'' }, //entregado 
         
    ]      
    let datosAux = []
    if(reembolso > 0){
        inactivos.push({estatus:9, imagen: reembolsoPendFinalizar, descEstatus: 'FINALIZADO', fecha:'' }) //finalizado 
    }
    if(datosHistoria.length > 0 ){
        datosHistoria.map((dato, index) => {

            let etapa = activos.find( item => item.estatus === dato.estatus );
            if(etapa){
                etapa.fecha = dato.fechaFormato;
                datosAux.push(etapa);
            }     
        })
    }        
   
    if (inactivos.length>0){
            inactivos.map(item => {          
            if( item.estatus > estatusActivo){
                datosAux.push(item)                 
            }
        })
    }

    return (<>   
    <div class="contenido-linea">
        <div class="linea" id="linea"></div>
        {
          datosAux.length > 0 ? datosAux.map(item => {
            let etapa = "inactivo"            
            if(item.estatus == estatusActivo){            
                etapa = "activo"
            }           
            return (                 
                <div class="contenidoEtapa"> 
                    <div class={etapa}>
                    <img src = {item.imagen} title={`${item.descEstatus}`}></img>                 
                    </div>
                    <p class="datosFecha">
                       {item.descEstatus}<br/>
                       <span>{item.fecha}</span>
                    </p>
                   
                </div>                             
                );
           }): <div></div>
        }
    </div>
       
      
    </>);
}
export default LineaSeguimiento;
