
// Url's
export const urlServidor = process.env.REACT_APP_URL_SERVER || "http://localhost:3500";
export const urlCliente = process.env.REACT_APP_URL_CLIENT || "http://localhost:3000";
export const strUrlSArchivos = process.env.REACT_APP_URL_MEDIA || "https://tytsoftwaredemexico.com.mx:4100";
export const empresa = process.env.REACT_APP_TIENDA_NAME || "HugoRios";

export const urlServidorScrum = process.env.REACT_APP_URL_SCRUM || "http://localhost:3530";

// Semilla
export const seed = process.env.SEED || "Y2FwZXJ1Y2l0YXJvamE=";
export const seedBackend = process.env.SEED_BACKEND || "c29mdHdvbmVAdG9ubGluZQ==";

// LocalStorage
export const TOKEN = "sesionToken";
export const USUARIO = "datosUsuario";
export const CARRITO = "miCarrito";

export const showFile = (archivo, name = 'file.pdf', extension) => {
    let blob = b64toBlob(archivo, extension);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute("download", name);
    a.download = name;
    a.click();
  };

  export async function b64tofile(b64, nombre, extension) {
    let resultado = '';
    let blob = b64toBlob(b64, extension);
    resultado = new File([blob], nombre, { type: extension });
  
    return resultado;
  
  }
  export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
  
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
  
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      let byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
  
    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }