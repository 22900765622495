import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setUsuario } from '../../redux/actions/loginAction';
import { setBusqueda } from '../../redux/actions/busquedaAction';
import { setCarrito } from '../../redux/actions/carritoActions';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// generic components
import HeaderBusqueda2 from '../../_layout/genericComponents/HeaderBusqueda2';
import Preloader from '../../_layout/genericComponents/Preloader';
import Modal from '../../_layout/NuevosComponentes/Modal';
import Footer from '../../_layout/genericComponents/Footer';
import * as Consultas from '../../componentes/Configuraciones/Consultas';

import { cleanLocalStorage } from '../../_layout/helpers/localstorage';
import { handleMenu } from '../../_layout/helpers/handles';

// components
import Login from '../LoginB';
import Registro from '../Registro';
import AlertaImagen from 'componentes/RecuperarPassword/Alerta';
import RecuperarPassword from 'componentes/RecuperarPassword/index'// 'componentes/LoginB/RecuperarPassword';

import logo from '../../assets/softwone.svg';
import loginSvg from '../../_layout/assets/svg/login.svg';
import storeSvg from '../../_layout/assets/svg/store.svg';
import logoFooter from '../../_layout/assets/svg/logotipo_interheco1.svg';

// material-ui icons
import { urlCliente } from "../../helpers";
import { useEnumPerfil } from "../../hooks/enums";

import routes from './rutas';
import Productos from "../Productos";
import LandingPage from '../LandingPage/index';
import { ContactlessOutlined } from '@mui/icons-material';
import { redondeo } from '../../helpers/regexp.js';
import {toFixed} from '../../helpers/regexp'

import moment from 'moment';


import FooterPagina from '../../_layout/NuevosComponentes/FooterPagina2/index';
import PopUpLogin from '../../_layout/NuevosComponentes/PopUpLogin';

function Principal() {

    let now = moment();
    const { usuario, token } = useSelector(state => state.login);
    const { nombre, perfilID } = usuario ? usuario : {};
    const { carrito } = useSelector(state => state.carrito);
    let cantidad = carrito ? carrito.length : 0;

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = (usuario) => dispatch(setUsuario(usuario));
    const setBusquedaRedux = (busqueda) => dispatch(setBusqueda(busqueda));
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));

    const [infoEmpresa, setInfoEmpresa] = useState({});
    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const opcionesUserNotLog = { open: true, formulario: "login", descripcion: "Iniciar sesión"};
    const { perfilAdministrador, perfilModerador, perfilUsuario } = useEnumPerfil(perfilID);

    const [state, setState] = useState(null);
    const [correo, setCorreo] = useState(null);
    const [redirigir, setRedirigir] = useState(null);
    const [openUsuario, setOpenUsuario] = useState(false);
    const [openOpciones, setOpenOpciones] = useState(usuario ? cleanOpenOpciones : opcionesUserNotLog);
    const [openOferta, setOpenOferta] = useState(false);
    const [importe, setImporte] = useState('0');
    const noTruncarDecimales = {maximumFractionDigits: 2,  minimumFractionDigits: 2, style:"currency", currency:"MXN"};

    const headerMenu = [{
        descripcion: "Editar perfil",
        onClick: () => {
            setOpenUsuario(!openUsuario);
            setRedirigir(getRederigir("/Perfil"));
        }
    }, { division: true }];

    let adminMenu = [];
    if (perfilAdministrador) {
        adminMenu = [{
            descripcion: "Configuraciones",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/Configuraciones"));
            }
        },
        {
            descripcion: "Paqueterías",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/paqueterias"));
            }
        },
        {
            descripcion: "Suscripciones",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/suscripciones"));
            }
        }, {
            descripcion: "Noticias",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/blog"));
            }
        },
        {
            descripcion: "Usuarios",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/usuarios"));
            }
        },
        /* {
            descripcion: "Slider",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/slider"));
            }
        }, */
        {
            descripcion: "Productos",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/productosAdmin"));
            }
        },
        {
            descripcion: "Pedidos",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/seguimientoPedido"));
            }
        },
        {
            descripcion: "Reportes",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/reportes"));
            }
        }];
    }

    let userMenu = [];
    if (perfilUsuario) {
        userMenu = [
            {
            descripcion: "Mis Pedidos",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/seguimientoPedido"));
            }
        }, /* {
            descripcion: "Mis Direcciones de entrega",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/direcciones"));
            }
            } ,
            descripcion: "Slider Público",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/sliderpublico"));
            }
        },*/ /* {
            descripcion: "Mis Favoritos",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/deseos"));
            }
        } *//* , {
            descripcion: "Reportes",
            onClick: () => {
                setOpenUsuario(!openUsuario);
                setRedirigir(getRederigir("/reportes"));
            }
        } */
        ];
    }

    const logoutMenu = [{ division: true },
    { descripcion: "Cerrar sesión", onClick: handleLogout }];

    function HandleRedirigir(Ruta) {
        setRedirigir(getRederigir(Ruta));
    }

    const getSvgIconStore = () => {
        return (
            <div style={{ width: "32px", height: "32px" }}>
                <img src={storeSvg} style={{ width: "32px", height: "32px" }}></img>
            </div>
        );
    };
    const getSvgIconLogin = () => {
        return (
            <div style={{ width: "32px", height: "32px" }}>
                <img src={loginSvg} style={{ width: "32px", height: "32px" }}></img>
            </div>
        );
    };

    const acciones = [
        {
            tipo: "listado",
            icono: getSvgIconLogin,
            descripcion: token ? nombre : "Usuario",
            open: openUsuario,
            openEvento: token ? () => setOpenUsuario(!openUsuario) : () => {return;},
            listado: token ? [...headerMenu, ...adminMenu, ...userMenu, ...logoutMenu] : [/* {
                descripcion: "Iniciar sesión",
                onClick: () => {
                    setOpenOpciones(openOpciones => ({
                        ...openOpciones,
                        open: !openOpciones.open,
                        formulario: "login",
                        descripcion: "Iniciar sesión"
                    }));
                    setOpenUsuario(openUsuario => !openUsuario);
                }
            }, {
                division: true
            }, {
                descripcion: "Registrarse",
                onClick: () => {
                    setOpenOpciones(openOpciones => ({
                        ...openOpciones,
                        open: !openOpciones.open,
                        formulario: "registro",
                        descripcion: "Registrarse"
                    }));
                    setOpenUsuario(openUsuario => !openUsuario);
                }
            } */]
        },
        {
            tipo: "boton",
            descripcion: "Carrito",
            notificaciones: cantidad ? cantidad : "0",
            icono: getSvgIconStore,
            onClick: (evt) => { setRedirigir(getRederigir("/carrito")) }
        },
        {
            tipo: "label",
            descripcion: `${importe}`,
            onClick: (evt) => { setRedirigir(getRederigir("/carrito")) }
        }];



    useEffect(() => {
        async function updateImporte() {
            if (carrito) {
                if (carrito.length > 0) {

                    let auxImporte = 0;
                    let arrCostos = [];

                    carrito.map((element) => {
                        arrCostos.push(Number(parseFloat(toFixed(element.precioVentaOnline, 2))) * element.cantidad);
                    })

                    for (let i = 0; i < arrCostos.length; i++) {
                        auxImporte += arrCostos[i];
                    }

                    let auxSubtotal = redondeo(auxImporte, 2);
                    auxSubtotal = parseFloat(auxSubtotal).toLocaleString('es-MX', noTruncarDecimales);
                    setImporte(auxSubtotal);
                }
                else {
                    setImporte('$0.00');
                }
            }
        }
        updateImporte();
    }, [carrito])

    function handleLogout() {
        setTimeout(() => {
            cleanLocalStorage();
            setTokenRedux(null);
            setUsuarioRedux(null);
            setCarritoRedux([]);
            setRedirigir(getRederigir());
            setOpenUsuario(false);
        }, 100);
    }

    if (location.pathname.includes("badToken")) {
        handleLogout();
    }

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            /* if (prop.name == "Inicio") {
                prop.component = infoEmpresa.paginaInicio == "LandingPage" ? LandingPage : Productos;
            }
            if (prop.path == "*") {
                prop.component = infoEmpresa.paginaInicio == "LandingPage" ? LandingPage : Productos;
            } */
            return <Route path={prop.path} component={prop.component} key={key} />;
        });
    };

    const history = useHistory();
    function getRederigir(url = "/", busqueda) {
        if (url.includes("?q=")) {
            history.push(`/productos?q=${busqueda}`);
        } else {
            history.push(url);
        }

        return (<><Switch><Redirect from="*" to={url} /></Switch></>);
    }

    function ftEffect() {
        async function effect() {
            let info = await Consultas.getEmpresasConfig({}, token);
            setInfoEmpresa(info);
            // console.log('info', info);
            document.documentElement.style.setProperty('--backgroundColor', info.backgroundColor);
            document.documentElement.style.setProperty('--primaryColor', info.primaryColor);
            document.documentElement.style.setProperty('--letraPrimaryColor', info.letraPrimaryColor);
            document.documentElement.style.setProperty('--secondaryColor', info.secondaryColor);
            document.documentElement.style.setProperty('--letraSecondaryColor', info.letraSecondaryColor);

            document.title = `TONLINE SOFTWONE`
            /* const favicon = document.getElementById("favicon");
            favicon.href = info.logoEmpresa; */
            let rutas = await handleMenu(routes, async (permisos) => {
                if (!permisos) return true;
                if (perfilAdministrador && permisos.administrador) return true;
                if (perfilModerador && permisos.moderador) return true;
                if (perfilUsuario && permisos.usuario) return true;
            });

            setState(rutas);
        } effect();
    }
    useEffect(ftEffect, [perfilAdministrador, perfilModerador, perfilUsuario]);

    function recuperarPassword(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "RecuperarPassword", open: true, descripcion: "RecuperarPassword" })
        //setOpenOpciones({...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
    }
    function enviarAlerta(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
        //setOpenOpciones({...openOpciones, formulario: "alerta", open: true, descripcion: "RecuperarPassword" })
    }
    function regresar(correo) {
        setCorreo(correo);
        setOpenOpciones({ ...openOpciones, formulario: "login", open: true, descripcion: "login" })
    }

    
    function showOferta(){
        const fechaInicio = moment(infoEmpresa.fechaInicioOferta);
        const fechaFin = moment(infoEmpresa.fechaFinOferta)
        // Obtenemos el formato YYYY-MM-DD omitiendo las horas del "moment"
        //let fecha = `${now.year()}-${now.month() < 9 ? `0${now.month() + 1}` : now.month() + 1}-${now.date()}`
        //const fechaActual = moment(fecha)
        const fechaActual = moment(now.format("YYYY-MM-DD"))

        if( infoEmpresa.imgOferta !== '' &&
            infoEmpresa.mostrarOferta === true &&
            fechaInicio <= fechaActual &&
            fechaActual <= fechaFin){
            return true;
        }
        
        return false;
    }

    return (state ? <>
        {(openOferta) && showOferta() && 
            <Modal
                claseAux="cOfertas"
                titulo="Aprovecha esta gran oferta"
                cModalChico={true}
                open={openOferta}
                cerrar={() => setOpenOferta(!openOferta)}
                >
                <div className='contenedorImagen'>
                    <img className='imagenOferta' src={infoEmpresa.imgOferta} alt="Imagen de Oferta"></img>
                </div>
            </Modal>
        }
        {redirigir}
        {(openOpciones.open && openOpciones.formulario === "login") &&
            <Modal
                claseAux="cIniciaSesion"
                titulo="INICIA SESIÓN"
                cModalChico={true}
                title={openOpciones.descripcion}
                open={openOpciones.open}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Login onClose={() => setOpenOpciones(cleanOpenOpciones)}
                    abrirRegistro={() => setOpenOpciones({ ...openOpciones, formulario: "registro", open: true })}
                    correo={correo}
                    onRecuperar={correo => {
                        recuperarPassword(correo);
                    }}
                    setOpenOferta={setOpenOferta}
                />
            </Modal>
        }
        {
            (openOpciones.open && openOpciones.formulario === "alerta") &&
            <AlertaImagen
                open={openOpciones.open}
                titulo={"Revisa tu correo y sigue las instrucciones"}
                correo={correo}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}
            />
        }
        {
            (openOpciones.open && openOpciones.formulario === "RecuperarPassword") &&
            <RecuperarPassword
                open={openOpciones.open}
                correo={correo}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}
                onEnviar={correo => {
                    enviarAlerta(correo)
                }
                }
                regresarPag={correo => regresar(correo)}
            />
        }

        {(openOpciones.open && openOpciones.formulario === "registro") &&
            <Modal
                claseAux="cRegistro"
                titulo={"CREAR UNA CUENTA"}
                cModalChico={true}
                open={openOpciones.open}
                onClose={() => setOpenOpciones(cleanOpenOpciones)}
                cerrar={() => setOpenOpciones(cleanOpenOpciones)}>
                <Registro onClose={() => setOpenOpciones(cleanOpenOpciones)} />
            </Modal>
        }
        <div style={{ backgroundColor: "var(--backgroundColor)", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
            <HeaderBusqueda2
                token={token}
                usuario = {usuario}
                setOpenOpciones={setOpenOpciones}
                redirigir={HandleRedirigir}
                brand={{
                    tipo: "imagen",
                    enlace: urlCliente,
                    descripcion: logo,
                    logoUrl: infoEmpresa.logoEmpresa
                }}
                paginaPrincipal={infoEmpresa.paginaInicio}
                titleBusqueda="Buscar"
                placeholderBusqueda="Capture la palabra a buscar"
                color={"white"}
                clase={"estiloHeader"}
                acciones={acciones}
                tipoBusqueda="texto"
                onSearch={busqueda => {
                    setBusquedaRedux(busqueda.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''));
                    setRedirigir(getRederigir(`productos${busqueda ? `?q=${busqueda.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}` : ""}`, busqueda.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')));
                }}
            >
                    <Switch>
                    {getRoutes(state)}
                    </Switch>

                    <FooterPagina/>
            </HeaderBusqueda2>
            
            
        </div>
        

    </> : <Preloader />);
}

export default Principal;
