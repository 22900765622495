import React, { useState, useRef, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Validaciones } from '@renedelangel/helpers';

// master components
import Tabla from '../../_layout/masterComponents/Tabla';
import FormularioModal from '../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

//generic components
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import { NetworkError } from "../../_layout/genericComponents/Metodos";
import Preloader from '../../_layout/genericComponents/Preloader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';

import { getPaqueteria, tablaPaqueteria } from '../../querys/Paqueterias/metodos';
import { addPaqueteria, updatePaqueteria, deletePaqueteria, updatePaqueteriaEstatus } from '../../mutations/Paqueterias/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handleAlertGeneric, handleAlertMutationGeneric, handelAlertEliminar, handleEliminar, handleGuardar } from '../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../_layout/helpers/colores';

import { rgxNumeros } from '../../helpers/regexp';

const { trim } = Validaciones;

function Paqueterias() {

    const { usuario, token } = useSelector(state => state.login);

    const cleanState = { paqueteriaID: null, paqueteria: "", descripcion: "", costo: "", ventaMinima: "", estatus: true };
    const cleanErrorState = {
        paqueteria: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        costo: { error: false, helperText: "" },
        ventaMinima: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" }

    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [seleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    // const [errorToken, setErrorToken] = useState(null);
    // const tokenError = useErrorToken(errorToken);


    let paqueteriaRef = useRef(null);
    let descripcionRef = useRef(null);
    let costoRef = useRef(null);
    let ventaMinimaRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "paqueteriaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getPaqueteria /*setErrorToken*/
        })
    }, {
        icono: DeleteIcon,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "paqueteriaID" }, { campo: "paqueteria" }, { campo: "descripcion" }, { campo: "costo" }, { campo: "ventaMinima" }],
        disabled: { multiseleccion: true },
        onClick: ({ paqueteriaID, paqueteria = "seleccionado" }) => handelAlertEliminar({
            setAlert,
            mensaje: `a la paqueteria ${paqueteria}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { paqueteriaID }, asyncDelete: deletePaqueteria /*setErrorToken*/
            })
        })
    }, {
        icono: RadioButtonUncheckedIcon,
        color: success,
        descripcion: "Cambiar Estatus",
        parametros: [{ campo: "estatus" }, { campo: "paqueteriaID" }],
        disabled: { multiseleccion: true },
        onClick: ({ paqueteriaID, estatus }) => handleAlertGeneric({
            setAlert,
            mensaje: {
                title: "¿Estas seguro?",
                descripcion: `Estás a punto de ${estatus ? "desactivar" : "activar"} el estatus de la paquetería, ¿Deseas continuar con el proceso?`,
                msjConfirmacion: `¡Si, ${estatus ? "desactivalo" : "activalo"}!`,
                msjCancelacion: "Mejor no, gracias"
            },
            onCancel: () => setAlert(null),
            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                mensajes: {
                    msjEspera: { descripcion: "Espere unos momentos estamos a punto de cambiar el estatus" },
                    msjCorrecto: {
                        title: "¡Se cambio el estatus!",
                        descripcion: `El estatus se cambio exitosamente a ${estatus ? "desactivado" : "activado"}`,
                        msjConfirmacion: "Gracias"
                    }
                },
                parametros: { input: { paqueteriaID, estatus: !estatus } }, asyncMutation: updatePaqueteriaEstatus /*setErrorToken*/
            })
        })
    }];

    const botones = [{
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "paqueteriaID",
        color: primary,
        title: "Paqueterías",
        iconTable: <AllInboxIcon />,
        headers: [
            { variable: "paqueteriaID", descripcion: "ID" },
            { variable: "paqueteria", descripcion: "Paquetería" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "costo", descripcion: "Costo" },
            { variable: "ventaMinima", descripcion: "Venta Mínima" },
            { variable: "estatus", descripcion: "Estatus" },

        ],
        responsiveTitle: ["paqueteriaID", "paqueteria", "descripcion", "costo", "ventaMinima", "estatus"],
        filter: [
            { campo: "paqueteria" },
            { campo: "descripcion" },

        ],
        selectFilter: [
            {
                campo: "estatus", placeholder: "Seleccionar estado del slider", retorna: "boolean",
                data: [
                    { value: null, label: "Mostrar todos" },
                    { value: "true", label: "Activos" },
                    { value: "false", label: "Inactivos" }
                ]
            }
        ],

        alineacion: [{ columnas: [0, 6], alineacion: "center" }],
        formato: [{ columnas: ["estatus"], tipo: "estatus" }],

    }

    const inputs = [{
        disabled,
        id: "paqueteria",
        value: state.paqueteria,
        error: errorState.paqueteria.error,
        success: state.paqueteria && !errorState.error ? true : undefined,
        helperText: errorState.paqueteria.helperText,
        inputRef: paqueteriaRef,
        title: "Paquetería*",
        placeholder: "Capture el nombre de la paquetería",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "paqueteria"),
        onKeyDown: (evt) => handleFocus(evt, paqueteriaRef)
    },


    {
        disabled,
        id: "descripcion",
        value: state.descripcion,
        error: errorState.descripcion.error,
        success: state.descripcion && !errorState.descripcion.error ? true : undefined,
        helperText: errorState.descripcion.helperText,
        inputRef: descripcionRef,
        title: "Descripción",
        placeholder: "Capture el nombre de la descripción",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "descripcion"),
        onKeyDown: (evt) => handleFocus(evt, descripcionRef)
    },

    {
        disabled,
        id: "costo",
        value: state.costo,
        error: errorState.costo.error,
        success: state.costo && !errorState.costo.error ? true : undefined,
        helperText: errorState.costo.helperText,
        inputRef: costoRef,
        title: "Costo*",
        placeholder: "Capture el costo",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "costo"),
        onKeyDown: (evt) => handleFocus(evt, costoRef)
    },

    {
        disabled,
        id: "ventaMinima",
        value: state.ventaMinima,
        error: errorState.ventaMinima.error,
        success: state.ventaMinima && !errorState.ventaMinima.error ? true : undefined,
        helperText: errorState.ventaMinima.helperText,
        inputRef: ventaMinimaRef,
        title: "Venta Mínima",
        placeholder: "Capture la venta mínima",
        grid: { md: 6, lg: 6 },
        onChange: ({ target: { value } }) => handleChange(value, "ventaMinima"),
        onKeyDown: (evt) => handleFocus(evt, ventaMinimaRef)
    },
    {
        disabled,
        id: "estatus",
        value: state.estatus,
        checked: state.estatus,
        error: errorState.estatus.error,
        success: state.estatus && !errorState.estatus.error ? true : undefined,
        helperText: errorState.estatus.helperText,
        title: "Estatus ",
        placeholder: "Seleccionar el estatus",
        tipo: "switch",
        data: seleccionables.estatus,
        grid: { md: 6, lg: 5 },
        onChange: () => setState(state => ({ ...state, estatus: !state.estatus }))
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: paqueteriaRef,
            mensajeCorrecto: `La paqueteria se ${state.paqueteriaID ? "modifico" : "agrego"} correctamente`,
            asyncCallback: ftGuardar,
            /*setErrorToken*/
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: CloseIcon,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ paqueteria, costo }) {

        let error;
        let validaciones = [{
            condicion: !paqueteria || trim(paqueteria) === "",
            ref: paqueteriaRef, keyError: "paqueteria",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas un nombre a la paqueteria"
        },
        {
            condicion: !rgxNumeros.test(costo), keyError: "costo",
            mensajeError: "Para que podamos funcionar bien necesitamos que definas un costo"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { paqueteriaID, paqueteria, descripcion, costo, ventaMinima, estatus } = state;

            let error = await handleValidaciones({ paqueteriaID, paqueteria, descripcion, costo, ventaMinima, estatus });

            if (error) return error;

            if (paqueteriaID) await updatePaqueteria(
                {
                    input: { paqueteriaID, paqueteria: trim(paqueteria), descripcion, costo: Number(costo), ventaMinima: Number(ventaMinima), estatus }
                }, token);
            else await addPaqueteria(
                {
                    input: { paqueteria: trim(paqueteria), descripcion, costo: Number(costo), ventaMinima: Number(ventaMinima), estatus }
                }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftEffect() {
        async function effect() {
            try {



            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                /* setErrorToken(message);*/
            }
        } effect();
    }

    useEffect(ftEffect, [actualizar]);

    return ((token) ? (usuario.usuarioID ? <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaPaqueteria}
            token={token}
        />
        <FormularioModal
            open={open}
            title={`${state.paqueteriaID ? "Modificar" : "Agregar"} Paqueteria`}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}

        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </> : <Preloader />) : <><Switch><Redirect fromn="*" to={"/"} /></Switch></>);

}

export default Paqueterias;
