// Constantes mensajes
export const errorTitle = "¡Te pedimos una disculpa!";
export const errorBoton = "Acepto las disculpas";

export const modificarSinDatos = "Lamentablemente no pudimos obtener la información requerida, verifica que alguién no la haya eliminado (o nuestros servicios pueden no encontrarse en su mejor momento)";

export const alertEliminarTitle = "¿Estás seguro?";
export const alertEliminarMsjConfirmacion = "¡Sí, elimínalo!";
export const alertEliminarMsjCancelacion = "Mejor no, gracias";
export const alertEliminarDescripcion = (mensaje) => `Estás a punto de eliminar ${mensaje}`;
//${/*", aunque si en algún lugar ya se está ocupando es posible que no puedas hacerlo"*/}

export const titleEspera = "¡Estámos en ello!";
export const genericDescripcionEspera = (mensaje) => `Si estás viendo este mensaje, es posible que la ${mensaje} del registro este tardando más de lo esperado, te pedimos un poco de paciencia`;

export const eliminarTitle = "¡Lo pudimos eliminar!";
export const eliminarDescripcion = "Esperámos que no te arrepientas después y también te deseamos un excelente día";
export const eliminarBoton = "Gracias, igualmente";




export const estadoTitle = "¡Se realizo la solicitud!";
export const estadoDescripcion = "Se envio un correo, por favor revisa tu buzon.";
export const estadoBoton = "Aceptar";

export const alertCambioEstadoTitle = "¿Desea solicitar una devolucion?";
export const alertCambioEstadoMsjConfirmacion = "Sí";
export const alertCambioEstadoMsjCancelacion = "No";
export const alertCambioEstadoDescripcion = (mensaje) => `${mensaje}`;
