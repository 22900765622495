
import { FetchAPI } from "@renedelangel/helpers";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { urlServidor } from '../../../helpers';
import { ENUM_SEXO, ENUM_PERFIL } from '../index';

export const enumSexo = async () => {
    try {

        let dominio = window.location.hostname.split('.')[0];
        let { getEnum } = await FetchAPI.graphql({
            variables: {dominio},
            url: urlServidor,
            query: ENUM_SEXO
        });

        return getEnum;

    } catch({message}) { throw new Error(NetworkError(message)); }
}

export const enumPerfil = async () => {
    try {
        let dominio = window.location.hostname.split('.')[0];
        let { getEnum } = await FetchAPI.graphql({
            variables: {dominio},
            url: urlServidor,
            query: ENUM_PERFIL
        });

        return getEnum;

    } catch({message}) { throw new Error(NetworkError(message)); }
}

export const selectEnumSexo  = async (variables) => {
    try {

        let sexos = await enumSexo(variables);

        if(!Array.isArray(sexos) || sexos.length === 0) throw new Error("No se encontraron resultados");

        return sexos.map(({ value, name, description }) => ({ value, label: description ? description : name }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const selectEnumPerfil = async (variables) => {
    try {

        let perfiles = await enumPerfil(variables);

        if(!Array.isArray(perfiles) || perfiles.length === 0) throw new Error("No se encontraron resultados");

        return perfiles.map(({ value, name, description }) => ({ value, label: description ? description : name }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}

export const selectEnumPerfilSinUsuario = async (variables) => {
    try {

        let perfiles = await enumPerfil(variables);

        if(!Array.isArray(perfiles) || perfiles.length === 0) throw new Error("No se encontraron resultados");
        perfiles = perfiles.filter(({name}) => name !== "Usuario" );
        return perfiles.map(({ value, name, description }) => ({ value, label: description ? description : name }));

    } catch({message:label}) {
        return [{
            label: NetworkError(label),
            value: null,
            disabled: true
        }]
    }
}
