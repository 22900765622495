///import config from './config'

//SOFTWONE
//Configuracion de produccion
//Llave publica key_evIdNmYzqQwN5mkyQS7qXO5
//Llave privada key_Fo5gtYX4mlHBDHR1AQKmE6
//////////////////////////////////////////////////////////////////////////
//Configuracion de pruebas
//Llave publica key_Fx3XueouZjJb88jlxw5u9mb
//Llave privada key_rP1I2PHlNEqxl5JZgrRtvH

//////////////////////////////////////////////////////////////////////////
//Llave de prueba general
//key_Ayxq6eHvasZqzxxBLz5jq4A
//////////////////////////////////////////////////////////////////////////
let publicKey = window.location.hostname.split('.',1)[0] === 'tonline' ? 'key_evIdNmYzqQwN5mkyQS7qXO5' : 'key_Fx3XueouZjJb88jlxw5u9mb';

const conektaHelper = {
  initConekta: () => {
    window.Conekta.setPublicKey(publicKey)
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc
      }
    }
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback)

    return [successCallback, successCallback];
  }
}

export default conektaHelper