import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


const GET_TARJETASXUSUARIO = `
query getTarjetasXUsuario($filtro: InputGetTarjetasXUsuario){
    getTarjetasXUsuario(filtro: $filtro){
        tarjetaID
        tokenID
        usuarioID
        referencia
    }
}
`
const GETALL_TARJETASXUSUARIO = `
query getAllTarjetasXUsuario($filtro: InputGetTarjetasXUsuario){
    getAllTarjetasXUsuario(filtro: $filtro){
        tarjetaID
        tokenID
        usuarioID
        referencia
        customerID
        cuentaCteID
    }
}
`
const TABLA_TARJETASXUSUARIO = `
query filterTarjetasXUsuario($filtro: InputFilterTarjetasXUsuario){
    filterTarjetasXUsuario(filtro: $filtro){
        listado{
        tarjetaID
        tokenID
        usuarioID
        referencia
        }
        registros
        paginas
    }
}
`

export const getTarjetasXUsuario = async (variables, token) => {
    try {
        let { getTarjetasXUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_TARJETASXUSUARIO
        })
        return getTarjetasXUsuario;
    }
    catch({ message }){ throw new Error(message) }
}

export const getAllTarjetasXUsuario = async (variables, token) => {
    try {
        let { getAllTarjetasXUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GETALL_TARJETASXUSUARIO
        })
        return getAllTarjetasXUsuario;
    }
    catch({ message }){ throw new Error(message) }
}

export const tablaTarjetasXUsuario = async (variable, token) => {
    try {
        
        let {
            tarjetaID,
            tokenID,
            usuarioID,
            referencia,
            pagina,
            limite 
        } = variable.filtro;

        let filtro = {
            tarjetaID: tarjetaID === null ? null : Number(tarjetaID),
            tokenID,
            usuarioID,
            referencia,
            pagina,
            limite 
        }

        let variables = {filtro};

        let { filterTarjetasXUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_TARJETASXUSUARIO
        });
        return filterTarjetasXUsuario;

    }
    catch({ message }){ throw new Error(message) }
}