import React, { useEffect, useState } from "react";
import './estilos.css';
import { Encriptar } from '@renedelangel/helpers';
import Boton from '../../NuevosComponentes/Boton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '../../../_layout/genericComponents/Modal';
import AlertaGenerica from '../../../_layout/genericComponents/AlertaGenerica';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../../componentes/Login';
import { upsertDeseos } from '../../../mutations/Deseos/metodos';
import PopUpLogin from "../../../_layout/NuevosComponentes/PopUpLogin";

const { Encrypt } = Encriptar;
function CarruselProductos({ items = [],
    Titulo = "Productos destacados",
    Etiqueda = "Oferta",
    mostrarBoton = false,
    mostrarDescripcion = false,
    actualizar,
    setActualizar,
    cambioPaginaAuto = false,
    enumerarPaginacion = false,
    flechasCambioPagina = false }) {

    const [stProdVisible, setProdVisible] = useState({ id: 0, clase: "cImgOcultoIzquierda" });
    const [stIdPaginasProductos, setIdPaginasProductos] = useState([]);
    const [stPaginasProductos, setPaginasProductos] = useState([]);
    const [stPaginacion, setPaginacion] = useState([]);
    const [stMover, setMover] = useState({ direccion: "izquierda" });
    const { usuario, token } = useSelector(state => state.login);
    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);

    const seed = "softwone";
    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);
    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlertGenerica({
            ...alertGenerica,
            visible: false
        });
    };

    function FillProductos() {
        let itemImg = [];
        let paginacion = [];
        let paginasProductos = [];
        let IdPaginasProductos = [];

        if (items === null) {
            items = [];
        }


        let auxItems = [...items];
        for (let i = 0; i < auxItems.length; i++) {
            const img = auxItems[i];
            let parametros = `{"id": "${img.id}"}`;
            let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&");
            let thref = `/producto/${url}`;

            itemImg.push(
                <div key={"divImgProd" + i} className="ImagenProd" name="imagenProd" titulo={img.titulo}>
                    <div className="cAuxContenedorProd">
                        <div className="cAuxOfertafavoritos">
                            <div className="cOfertaLabel">
                                {Etiqueda}
                            </div>
                            <div className="cIconoFavoritos" onClick={() => agregarListaDeseos({ productoID: img.id, deseosID: img.deseosID })}>
                                <Tooltip id={Titulo + "_" + Etiqueda + "_" + img.id + "_" + i} title={img.deseosID ? "Quitar de mis favoritos" : "Agregar a mis favoritos"} placement="bottom">
                                    {img.deseosID ?
                                        <FavoriteOutlinedIcon style={{ fontSize: 'inherit', color: "var(--primaryColor)" }} /> :
                                        <FavoriteBorderIcon style={{ fontSize: 'inherit', color: "var(--primaryColor)" }} />}
                                </Tooltip>
                            </div>
                        </div>
                        <a href={thref} className="cRef">
                            <img src={img.imagen} className={"imgProd"} />
                        </a>
                    </div>
                    <div className="descripcionImagenProd">
                        {
                            mostrarDescripcion &&
                            <div className="descripcionImagenProdCenter">
                                {img.descripcion}
                            </div>
                        }
                        {
                            mostrarBoton &&
                            <div className="btnAgregarCarrito">
                                <a href={thref}>
                                        <Boton titulo="Agregar al carrito" colorFondo="var(--secondaryColor)" colorLetra="var(--letraSecondaryColor)" />
                                </a>
                            </div>
                        }
                    </div>
                </div >
            );

            if (itemImg.length === 4) {
                let key = paginasProductos.length;
                
                paginacion.push(<div key={"paginacion" + key} onClick={() => setProdVisible({ id: key, clase: "cImgOcultoIzquierda" })}
                    className={"itemPaginacion " + (stProdVisible.id === key ? "cPaginaActiva" : "")}>
                        {enumerarPaginacion && (i+1)/4}
                </div>);

                IdPaginasProductos.push(key);
                paginasProductos.push(
                    <div key={"divImgProd" + key}
                        draggable="true"
                        className={"ContenedorImagenesMovilProd " + (stProdVisible.id === key ? "" : stProdVisible.clase)}>
                        {itemImg}
                    </div>);
                itemImg = [];
            }
        }

        if (itemImg.length > 0) {
            let key = paginasProductos.length;

            paginacion.push(<div key={"paginacion" + key} onClick={() => setProdVisible({ id: key, clase: "cImgOcultoIzquierda" })}
                className={"itemPaginacion " + (stProdVisible.id === key ? "cPaginaActiva" : "")}>
                    {enumerarPaginacion && Math.ceil(auxItems.length/4)}
            </div>);

            paginasProductos.push(
                <div key={"divImgProd" + key}
                    draggable="true"
                    className={"ContenedorImagenesMovilProd " + (stProdVisible.id === key ? "" : stProdVisible.clase)}>
                    {itemImg}
                </div>);
            itemImg = [];
            IdPaginasProductos.push(key);
        }
        
        flechasCambioPagina && (paginacion = [
            <div 
                // key={"anterior"} 
                className={"flecha itemPaginacion"} 
                onClick={() => cambiarPaginaDireccionales("izquierda")}>
                &#10094;
            </div>,
            ...paginacion,
            <div 
                // key={"siguiente"} 
                className={"flecha itemPaginacion"} 
                onClick={() => cambiarPaginaDireccionales("derecha")}>
                &#10095;
            </div>
        ]);

        setIdPaginasProductos(IdPaginasProductos);
        setPaginasProductos(paginasProductos);
        setPaginacion(paginacion);
    }

    function AgregarSwipe() {
        let container = document.getElementById("ContenedorImagenesFijoProd" + Titulo);
        container.addEventListener('touchstart', touchStart, false);
        container.addEventListener('touchmove', touchMove, false);
    }

    function touchStart(e) {

        let contenedor = document.getElementById("ContenedorImagenesFijoProd" + Titulo);
        let touch = e.touches[0];

        contenedor.setAttribute("startX", touch.pageX);
        if (!contenedor.getAttribute("AnchorLeft")) {
            contenedor.setAttribute("AnchorLeft", 0);
        }
    }

    function touchMove(e) {

        let contenedor = document.getElementById("ContenedorImagenesFijoProd" + Titulo);
        let touch = e.touches[0];
        let positionX = touch.pageX;
        let startX = contenedor.getAttribute("startX");

        if (parseInt(startX) === -1) {
            return;
        }

        let moverN = positionX - startX;

        if (moverN < 0) {
            if (moverN < -60) {
                setMover({ direccion: "derecha" });
                contenedor.setAttribute("startX", -1);
            }
        } else {
            if (moverN > 60) {
                setMover({ direccion: "izquierda" });
                contenedor.setAttribute("startX", -1);
            }
        }
    }

    async function ftAgregar({ deseosID, productoID }) {
        try {
            let deseo = await upsertDeseos({
                input: { deseosID, productoID: Number(productoID), clienteID: usuario.usuarioID }
            }, token);

            let msj = deseo ? `Producto agregado a sus favoritos` : `Se quitó el producto de sus favoritos`;

            setActualizar(!actualizar);
            setAlertGenerica({
                visible: true,
                descripcion: msj,
                tipo: "success",
            });
        } catch (error) {
            setAlertGenerica({
                visible: true,
                descripcion: `Error: ${error}`,
                tipo: "error",
            });
        }
    }

    function agregarListaDeseos({ deseosID, productoID }) {
        if (usuario !== null) {
            ftAgregar({ deseosID, productoID });
        }
        else {
            //setFavorito(likeFavorito => (true));
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: true,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }

    function cambiarPagina(){
        if (stMover.direccion === "derecha") {
            for (let i = 0; i < stIdPaginasProductos.length; i++) {
                const auxPagina = stIdPaginasProductos[i];
                if (parseInt(stProdVisible.id) === parseInt(auxPagina)) {
                    if ((i + 1) === stIdPaginasProductos.length) {
                        setProdVisible({ id: stIdPaginasProductos[0], clase: "cImgOcultoIzquierda" });
                    } else {
                        setProdVisible({ id: stIdPaginasProductos[i + 1], clase: "cImgOcultoIzquierda" });
                    }
                }
            }
        } else {
            for (let i = 0; i < stIdPaginasProductos.length; i++) {
                const auxPagina = stIdPaginasProductos[i];
                if (parseInt(stProdVisible.id) === parseInt(auxPagina)) {
                    if ((i - 1) < 0) {
                        setProdVisible({ id: stIdPaginasProductos[stIdPaginasProductos.length - 1], clase: "cImgOcultoIzquierda" });
                    } else {
                        setProdVisible({ id: stIdPaginasProductos[i - 1], clase: "cImgOcultoIzquierda" });
                    }
                }
            }
        }    
    }

    // Cambiar pagina a traves de las flechas "<" ">"
    async function cambiarPaginaDireccionales(dir){
        if(stMover.direccion !== dir){
            await setMover({direccion: dir});
        }
        cambiarPagina();
    }

    useEffect(() => {
        cambiarPagina();
    }, [stMover]);

    useEffect(() => {
        FillProductos();
        AgregarSwipe();
    }, [stProdVisible, items, actualizar]);


    // Control de animacion automatica de paginacion del carrusel
    useEffect(() => {
        if(cambioPaginaAuto){  
            const intervalo = setInterval(() => {
                setMover({direccion: "derecha"})
                cambiarPagina();
            }, 5000);
            return () => clearInterval(intervalo);
        }
    })

    return (
        <>
            <div className="contenedorCarouselProd">
                <div className={"TituloCarruselProd " + (Titulo ? "" : "cSinBorde")}>
                    {Titulo}
                </div>
                <div id={"ContenedorImagenesFijoProd" + Titulo} className="ContenedorImagenesFijoProd">
                    {stPaginasProductos}
                </div>
                <div className="ContenedorPaginacion">
                    {stPaginacion}
                </div>
            </div>
            {alertGenerica && <AlertaGenerica
                visible={alertGenerica.visible}
                descripcion={alertGenerica.descripcion}
                tipo={alertGenerica.tipo}
                handleClose={handleCloseMensaje}
            />}
            
            <PopUpLogin 
            openOpciones={openOpciones}
            setOpenOpciones={setOpenOpciones}
            />
        </>)
}

export default CarruselProductos;
