import React, {useState, useEffect} from 'react';
import './styles.css';
import Modal from '../Modal';
import {
    getDireccion,
    tablaDireccion,
    getCodigoPostal,
    selectTipoDireccion,
    selectDirecciones
} from "../../../querys/Direcciones/metodos";
import {
    addDireccion,
    updateDireccion,
    deleteDireccion,
    updateCambioTipoDireccion
} from "mutations/Direcciones/metodos";

import {
    vectorSaveIcon
} from '../../assets/svg/exportsvg';
import { rgxNumeros, rgxNumerosMaximo10 } from "../../../helpers/regexp";

import { trim } from '@renedelangel/helpers/lib/Validaciones';

export default function PopUpAgregarDireccion(props){

    let {
        openAgregarDireccion,
        setOpenAgregarDireccion,
        usuario, 
        token,
        actualizar,
        setActualizar,
        propsSetAlert
    } = props;

    const clearState = {
        asentamiento: "",
        calle: "",
        cambioTipo: "",
        celular: "",
        ciudad: "",
        codigoPostal: "",
        codigoPostalID: "",
        direccionFormateada: "",
        direccionID: "",
        edificio: "",
        entreCalle: "",
        estado: "",
        municipio: "",
        nombreDireccion: "",
        nombreResponsable: "",
        numeroExterior: "",
        numeroInterior: "",
        pais: "México",
        telefonoOficina: "",
        telefonoParticular: "",
        tipoAsentamiento: "",
        tipoDireccion: "",
        usuarioID: "",
        yCalle: "",
        zona: ""
    };

    const cleanErrorState = {
        asentamientoError: { error: false, mensaje: "" },
        calleError: { error: false, mensaje: "" },
        cambioTipoError: { error: false, mensaje: "" },
        celularError: { error: false, mensaje: "" },
        ciudadError: { error: false, mensaje: "" },
        codigoPostalError: { error: false, mensaje: "" },
        codigoPostalIDError: { error: false, mensaje: "" },
        direccionFormateadaError: { error: false, mensaje: "" },
        direccionIDError: { error: false, mensaje: "" },
        edificioError: { error: false, mensaje: "" },
        entreCalleError: { error: false, mensaje: "" },
        estadoError: { error: false, mensaje: "" },
        municipioError: { error: false, mensaje: "" },
        nombreDireccionError: { error: false, mensaje: "" },
        nombreResponsableError: { error: false, mensaje: "" },
        numeroExteriorError: { error: false, mensaje: "" },
        numeroInteriorError: { error: false, mensaje: "" },
        paisError: { error: false, mensaje: "" },
        telefonoOficinaError: { error: false, mensaje: "" },
        telefonoParticularError: { error: false, mensaje: "" },
        tipoAsentamientoError: { error: false, mensaje: "" },
        tipoDireccionError: { error: false, mensaje: "" },
        usuarioIDError: { error: false, mensaje: "" },
        yCalleError: { error: false, mensaje: "" },
        zonaError: { error: false, mensaje: "" }
    };

    const [state, setState] = useState(clearState);
    const [colonias, setColonias] = useState([]);
    const [loader, setLoader] = useState(false);
    const [errorState, setErrorState] = useState(cleanErrorState);

    function handleChange(e) {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    }

    let {
        asentamiento,
        calle,
        cambioTipo,
        celular,
        ciudad,
        codigoPostal,
        codigoPostalID,
        direccionFormateada,
        direccionID,
        edificio,
        entreCalle,
        estado,
        municipio,
        nombreDireccion,
        nombreResponsable,
        numeroExterior,
        numeroInterior,
        pais,
        telefonoOficina,
        telefonoParticular,
        tipoAsentamiento,
        tipoDireccion,
        usuarioID,
        yCalle,
        zona
    } = state;

    let {
        asentamientoError,
        calleError,
        cambioTipoError,
        celularError,
        ciudadError,
        codigoPostalError,
        codigoPostalIDError,
        direccionFormateadaError,
        direccionIDError,
        edificioError,
        entreCalleError,
        estadoError,
        municipioError,
        nombreDireccionError,
        nombreResponsableError,
        numeroExteriorError,
        numeroInteriorError,
        paisError,
        telefonoOficinaError,
        telefonoParticularError,
        tipoAsentamientoError,
        tipoDireccionError,
        usuarioIDError,
        yCalleError,
        zonaError
    } = errorState;

    function handleValidaciones({
        nombreDireccion,
        nombreResponsable,
        codigoPostalID,
        codigoPostal,
        pais,
        estado,
        municipio,
        tipoAsentamiento,
        calle,
        entreCalle,
        yCalle,
        celular,
        telefonoParticular,
        telefonoOficina,
        tipoDireccion
    }) {
        let error = 0;
        let validaciones = [
            {
                condicion: !nombreDireccion || trim(nombreDireccion) === "",
                keyError: "nombreDireccion",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que le definas un nombre a la dirección "
            },
            {
                condicion: !nombreResponsable || trim(nombreResponsable) === "",
                keyError: "nombreResponsable",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que ingreses un nombre "
            },
            {
                condicion: !rgxNumeros.test(codigoPostal),
                keyError: "codigoPostal",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas un código postal válido. Solo acepta datos de tipo numérico"
            },
            /* {
                condicion: !asentamiento || asentamiento == "",
                keyError: "asentamiento",
                mensajeError: "Debe seleccionar una colonia"
            }, */
            {
                condicion: !pais || trim(pais) === "",
                keyError: "pais",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el País"
            }/* ,
            {
                condicion: !estado || trim(estado) === "",
                keyError: "estado",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el Estado"
            },
            {
                condicion: !municipio || trim(municipio) === "",
                keyError: "municipio",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el municipio"
            },
            {
                condicion: !tipoAsentamiento || trim(tipoAsentamiento) === "",
                keyError: "tipoAsentamiento",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas el tipo de asentamiento"
            },
            {
                condicion: !calle || trim(calle) === "",
                keyError: "calle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la calle"
            },
            {
                condicion: !entreCalle || trim(entreCalle) === "",
                keyError: "entreCalle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la entre calle"
            },
            {
                condicion: !yCalle || trim(yCalle) === "",
                keyError: "yCalle",
                mensajeError:
                    "Para que podamos funcionar bien necesitamos que definas la calle"
            },
            {
                condicion: !rgxNumerosMaximo10.test(celular),
                keyError: "celular",
                mensajeError:
                    "Solo acepta datos de tipo numérico y deben ser 10 dígitos"
            },
            {
                condicion:
                    telefonoParticular &&
                    !rgxNumerosMaximo10.test(telefonoParticular),
                keyError: "telefonoParticular",
                mensajeError:
                    "El teléfono particular solo permite ingresar 10 números"
            },
            {
                condicion:
                    telefonoOficina &&
                    !rgxNumerosMaximo10.test(telefonoOficina),
                keyError: "telefonoOficina",
                mensajeError:
                    "El teléfono de oficina solo permite ingresar 10 números"
            } */
        ];

        validaciones.reverse();

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (condicion) {
                setErrorState({
                    ...cleanErrorState,
                    [`${keyError}Error`]: { error: true, mensaje: mensajeError }
                });
                return (error = 1);
            }
        });

        return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            setLoader(true);
            let error = 0;
            error = handleValidaciones({
                nombreDireccion,
                nombreResponsable,
                codigoPostalID,
                codigoPostal,
                pais,
                estado,
                municipio,
                tipoAsentamiento,
                calle,
                entreCalle,
                yCalle,
                celular,
                telefonoParticular,
                telefonoOficina,
                tipoDireccion
            });

            if (error == 0) {
                try {
                    await addDireccion(
                        {
                            input: {
                                nombreDireccion,
                                nombreResponsable,
                                pais,
                                codigoPostalID: Number(codigoPostalID),
                                estado,
                                municipio,
                                ciudad,
                                zona,
                                tipoAsentamiento,
                                calle: '',
                                numeroExterior: null,
                                numeroInterior: null,
                                entreCalle: '',
                                yCalle: '',
                                celular: '',
                                telefonoParticular: null,
                                telefonoOficina: null,
                                tipoDireccion: 2,
                                asentamiento: ''
                            }
                        },
                        token
                    );
                    setActualizar(!actualizar);
                    setLoader(false);
                    setState(clearState);
                    setColonias([]);
                    setErrorState(cleanErrorState);
                    setOpenAgregarDireccion(!openAgregarDireccion);
                    propsSetAlert({
                        descripcion: "Se ha guardado de forma correcta su dirección",
                        title: "¡Éxito!",
                        tipo: "success",
                        msjConfirmacion: "Aceptar",
                        onConfirm: () => {
                            propsSetAlert(null);
                        },
                        showCancel: false,
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });


                } catch ({ message }) {
                    setLoader(false);
                    throw new Error(message);
                }
            } else {
                setLoader(false);
                return;
            }
        }
        ftGuardar();
    }

    useEffect(() => {
        async function infoCP() {
            if (
                state.codigoPostal.length < 5 ||
                state.codigoPostal.length > 5
            ) {
                setState(state => ({
                    ...state,
                    asentamiento: "",
                    claveMunicipio: "",
                    municipio: "",
                    estado: "",
                    claveEstado: "",
                    zona: "",
                    ciudad: "",
                    tipoAsentamiento: "",
                    colonias: "",
                    codigoPostalID: ""
                }));
                setColonias([]);
            }

            if (state.codigoPostal.length === 5) {
                let aux = await getCodigoPostal(
                    { cp: state.codigoPostal },
                    token
                );

                if (aux.codigoPostalID !== null) {
                    let {
                        dCiudad,
                        dEstado,
                        dMunicipio,
                        dZona,
                        cMunicipio,
                        cEstado,
                        cTipoAsentamiento,
                        colonias,
                        codigoPostalID
                    } = aux;
                    setState({
                        ...state,
                        claveMunicipio: cMunicipio ? cMunicipio : "",
                        municipio: dMunicipio ? dMunicipio : "",
                        estado: dEstado ? dEstado : "",
                        claveEstado: cEstado ? cEstado : "",
                        zona: dZona ? dZona : "",
                        ciudad: dCiudad ? dCiudad : "",
                        tipoAsentamiento: cTipoAsentamiento
                            ? cTipoAsentamiento
                            : "",
                        codigoPostalID: codigoPostalID
                    });

                    const newColonias = colonias.map(
                        ({
                            idAsentamientoCpcons: value,
                            dAsentamiento: label,
                            dTipoAsentamiento
                        }) => ({ value, label, dTipoAsentamiento })
                    );

                    setColonias(newColonias);
                }
            }
        }
        infoCP();
    }, [state.codigoPostal]);



    return(
    <>{
        openAgregarDireccion ? 
        <div className='cg_PopUpAgregarDireccion'>
    <Modal
    open={openAgregarDireccion}
    cerrar={() => {
        setState(clearState);
        setColonias([]);
        setErrorState(cleanErrorState);
        setOpenAgregarDireccion(!openAgregarDireccion);
    }
    }
    >
                <div className="c_campos_formulario">
                                <div className="c_titulo_formulario">
                                    <h6>Agregar una dirección de facturación</h6>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_calle_nombre_direccion">
                                        <label for="nombreDireccion">
                                            <h6>Nombre dirección*</h6>
                                        </label>
                                        <input
                                            id="nombreDireccion"
                                            placeholder="Nombre dirección *"
                                            name={"nombreDireccion"}
                                            value={nombreDireccion}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: nombreDireccionError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {nombreDireccionError.error
                                                    ? nombreDireccionError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>

                                    <div className="fr_responsable">
                                        <label for="nombreResponsable">
                                            <h6>Responsable*</h6>
                                        </label>
                                        <input
                                            placeholder="Responsable*"
                                            name={"nombreResponsable"}
                                            value={nombreResponsable}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />

                                        <label>
                                            <h6
                                                style={{
                                                    color: nombreResponsableError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {nombreResponsableError.error
                                                    ? nombreResponsableError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_cp">
                                        <label for="codigoPostal">
                                            <h6>Código postal*</h6>
                                        </label>
                                        <input
                                            placeholder="Código postal"
                                            name={"codigoPostal"}
                                            value={codigoPostal}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: codigoPostalError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {codigoPostalError.error
                                                    ? codigoPostalError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>


                                    <div className="fr_pais">
                                        <label for="pais">
                                            <h6>Pais*</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Pais"
                                            name={"pais"}
                                            value={pais}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>

                                   {/*  <div className="fr_colonia">
                                        <label for="asentamiento">
                                            <h6>Colonia*</h6>
                                        </label>
                                        <select
                                            id="asentamiento"
                                            name={"asentamiento"}
                                            value={asentamiento}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        >
                                            <option
                                                value={""}
                                                disabled
                                                selected
                                            >
                                                Seleccione la colonia
                                            </option>
                                            {colonias.map((element, index) => {
                                                return (
                                                    <option
                                                        value={element.label}
                                                        key={element.value}
                                                    >
                                                        {element.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label>
                                            <h6
                                                style={{
                                                    color: asentamientoError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {asentamientoError.error
                                                    ? asentamientoError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div> */}
                                </div>

                                {/* <div className="cf_fr">
                                    <div className="fr_pais">
                                        <label for="pais">
                                            <h6>Pais*</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Pais"
                                            name={"pais"}
                                            value={pais}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_estado">
                                        <label for="estado">
                                            <h6>Estado</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            id={"estado"}
                                            placeholder="Estado"
                                            name={"estado"}
                                            value={estado}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="cf_fr">
                                    <div className="fr_municipio">
                                        <label for="municipio">
                                            <h6>Municipio</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Municipio"
                                            name={"municipio"}
                                            value={municipio}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_ciudad">
                                        <label for="ciudad">
                                            <h6>Ciudad</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Ciudad"
                                            name={"ciudad"}
                                            value={ciudad}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div> */}

                                {/* <div className="cf_fr">
                                    <div className="fr_zona">
                                        <label for="zona">
                                            <h6>Zona</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Zona"
                                            name={"zona"}
                                            value={zona}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="fr_tipo_asentamiento">
                                        <label for="tipoAsentamiento">
                                            <h6>Tipo de Asentamiento</h6>
                                        </label>
                                        <input
                                            disabled={true}
                                            placeholder="Tipo de Asentamiento"
                                            name={"tipoAsentamiento"}
                                            value={tipoAsentamiento}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                    </div>
                                </div> */}

                                {/* <div className="cf_fr">
                                    <div className="fr_calle">
                                        <label for="calle">
                                            <h6>Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Calle*"
                                            name={"calle"}
                                            value={calle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: calleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {calleError.error
                                                    ? calleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_numero_exterior">
                                        <label for="numeroExterior">
                                            <h6>Número Exterior*</h6>
                                        </label>
                                        <input
                                            placeholder="Número Exterior"
                                            name={"numeroExterior"}
                                            value={numeroExterior}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: numeroExteriorError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {numeroExteriorError.error
                                                    ? numeroExteriorError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div> */}

                                {/* <div className="cf_fr">
                                    <div className="fr_numero_interior">
                                        <label for="numeroInterior">
                                            <h6>Número Interior</h6>
                                        </label>
                                        <input
                                            placeholder="Número Interior"
                                            name={"numeroInterior"}
                                            value={numeroInterior}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: numeroInteriorError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {numeroInteriorError.error
                                                    ? numeroInteriorError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_entre_calle">
                                        <label for="entreCalle">
                                            <h6>Entre Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Entre Calle*"
                                            name={"entreCalle"}
                                            value={entreCalle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: entreCalleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {entreCalleError.error
                                                    ? entreCalleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div> */}

                               {/*  <div className="cf_fr">
                                    <div className="fr_y_calle">
                                        <label for="yCalle">
                                            <h6>Y Calle*</h6>
                                        </label>
                                        <input
                                            placeholder="Y calle*"
                                            name={"yCalle"}
                                            value={yCalle}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: yCalleError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {yCalleError.error
                                                    ? yCalleError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_celular">
                                        <label for="celular">
                                            <h6>Celular*</h6>
                                        </label>
                                        <input
                                            placeholder="Celular*"
                                            name={"celular"}
                                            value={celular}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: celularError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {celularError.error
                                                    ? celularError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div> */}

                                {/* <div className="cf_fr">
                                    <div className="fr_tel_particular">
                                        <label for="telefonoParticular">
                                            <h6>Tel. Particular</h6>
                                        </label>
                                        <input
                                            placeholder="Tel. Particular"
                                            name={"telefonoParticular"}
                                            value={telefonoParticular}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: telefonoParticularError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {telefonoParticularError.error
                                                    ? telefonoParticularError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                    <div className="fr_tel_oficina">
                                        <label for="telefonoOficina">
                                            <h6>Tel. Oficina</h6>
                                        </label>
                                        <input
                                            placeholder="Tel. Oficina"
                                            name={"telefonoOficina"}
                                            value={telefonoOficina}
                                            onChange={e => {
                                                handleChange(e);
                                            }}
                                        />
                                        <label>
                                            <h6
                                                style={{
                                                    color: telefonoOficinaError.error
                                                        ? "red"
                                                        : "var(--secondaryColor)"
                                                }}
                                            >
                                                {telefonoOficinaError.error
                                                    ? telefonoOficinaError.mensaje
                                                    : ""}
                                            </h6>
                                        </label>
                                    </div>
                                </div> */}
                            </div>

                            <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div
                            className="button_guardar_direccion"
                            onClick={() => {
                                ftGuardar();
                            }}
                        >
                            <h6>
                                GUARDAR DIRECCION
                            </h6>
                            {vectorSaveIcon}
                        </div>
                        </div>
    </Modal>
    </div>
    :
    <></>
    }</>
)
}