import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { Validaciones } from "@renedelangel/helpers";
import { Encriptar } from '@renedelangel/helpers';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import AlertaGenerica from '../../../_layout/genericComponents/AlertaGenerica';
import Login from '../../../componentes/Login';
import { upsertDeseos } from '../../../mutations/Deseos/metodos';
import Modal from "_layout/genericComponents/Modal";
import CardProducto from "../CardProducto";
import { toFixed } from "helpers/regexp";
import { useParams, useLocation } from "react-router-dom";
import LoaderLogoSoftwone2 from "_layout/assets/svg/Animaciones/logoSoftwone2";
import confirmarPedidoStyle from "_layout/assets/cssComponentes/confirmarPedido";
import PopUpLogin from "../PopUpLogin";


const { CompararDosObjetos } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;

export default function     TablaProductos({
    asyncData,
    infoSeccion,
    parametrosFijos = {},
    token,
    mostrarFiltros = true,
    gruposIDS = [],
    flechasCambioPagina = false
}) {
    const {
        limiteInicial = 15,
        filtro,
        title,
        icon,
        hideHeader = false,
        ocultarListaRegistros,
        busqueda,
        actualizar = false,
        leyenda,
        leyenda2,
        grupoInicial,
        subgrupoInicial
    } = infoSeccion;


    

    const history = useHistory();
    const seed = "softwone";
    let { id } = useParams();
    let location = useLocation();
    let url = id == undefined ? null : id.replace(/&/g, "/");
    let datos = url !== null ? (Decrypt({ data: url, seed }) !== ""
            ? JSON.parse(Decrypt({ data: url, seed }))
            : null ) : null

    
    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    
    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);
    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlertGenerica({
            ...alertGenerica,
            visible: false
        });
    };

    const [paginacion, setPaginacion] = useState({
        paginas: 1,
        listadoPaginas: 5,
        registros: 0,
        limite: !isNaN(Number(limiteInicial)) ? limiteInicial : 15,
        busqueda: {
            gruposIDs: grupoInicial !== '' ?  grupoInicial : null,
            subgruposIDs: datos !== null ? datos.id : null
        },
        onClick: activa => setPaginaActiva(activa)
    });
    const { usuario } = useSelector(state => state.login);
    const [data, setData] = useState([]);
    const [paginaActiva, setPaginaActiva] = useState(1);
    const [error, setError] = useState();
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [dataFiltrada, setDataFiltrada] = useState([]);
    const [value, setValue] = useState([0, 35000]);
    const [openFiltroDepartamentos, setOpenFiltroDepartamentos] = useState(
        false
    );
    const [openFiltroCategorias, setOpenFiltroCategorias] = useState(false);
    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [actualizarTbl, setActualizarTbl] = useState(false)

    const registrosRef = useRef(null);

    let newFiltro = null;
    if (filtro && typeof filtro === "object") {
        Object.keys(filtro).forEach(key => {
            newFiltro = !newFiltro ? [] : newFiltro;
            switch (filtro[key].tipo) {
                case "switch":
                    if (
                        Array.isArray(filtro[key].data) &&
                        filtro[key].data.length > 0
                    )
                        filtro[key].data.forEach(info => {
                            let input = {
                                onChange: value => {
                                    setPaginacion(paginacion => ({
                                        ...paginacion,
                                        busqueda: {
                                            ...paginacion.busqueda,
                                            [key]:
                                                paginacion.busqueda[key] &&
                                                Array.isArray(
                                                    paginacion.busqueda[key]
                                                ) &&
                                                paginacion.busqueda[key]
                                                    .length > 0
                                                    ? [
                                                          ...paginacion
                                                              .busqueda[key],
                                                          value
                                                      ]
                                                    : [value]
                                        }
                                    }));
                                }
                            };
                        });
                    break;
                default:
                    break;
            }
        });
    }

    useEffect(() => {
        async function ftFiltroParams(){
            if(datos !== null){
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ["subgruposIDs"]: datos.id
                }
            }));
        }
        if(grupoInicial !== ''){
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ["gruposIDs"]: grupoInicial
                }
            }));
        }
        }
        ftFiltroParams()
    }, [location.pathname, grupoInicial])


    function ftEffect() {
        if (!asyncData) return;
        async function effect() {


            try {
                setPaginacion(paginacion => ({
                    ...paginacion,
                    registros: 0,
                    paginas: 0
                }));
                setError(error => ({ mensaje: "", ok: false }));
                setLoader(true);

                let params = {};
                if (parametrosFijos && typeof parametrosFijos === "object")
                    params = { ...parametrosFijos };
                if (busqueda && Object.keys(busqueda).length > 0) {
                    params = { ...params, ...busqueda };
                    /* setSearch(busqueda); */
                }
                let gruposSelec = paginacion.busqueda.gruposIDs;
                gruposIDS(gruposSelec);

                if(paginacion.busqueda.subgruposIDs !== null || grupoInicial !== ''){
                    busqueda.busqueda = null
                    params.busqueda = '' ;
                }

                if( grupoInicial !== '') {
                    busqueda.busqueda = null
                    params.busqueda = '' ;
                    paginacion.busqueda.gruposIDs = grupoInicial;
                    paginacion.busqueda.subgruposIDs = null;
                }

                if(busqueda.busqueda !== '' && busqueda.busqueda !== null && busqueda.busqueda !== "null" ){
                    paginacion.busqueda.gruposIDs = null;
                    paginacion.busqueda.subgruposIDs = null;
                }


                let filtro = {
                    ventaOnline: 1,
                    limite: paginacion.limite,
                    pagina: paginaActiva,
                    precioMaximo: 99999999,
                    precioMinimo: 1,
                    ...paginacion.busqueda,
                    ...params
                };

                let {
                    props,
                    formato,
                    grid,
                    msjSinRegistros,
                    actions
                } = infoSeccion;

                let resultado = await asyncData({ filtro }, token);

                /*if(typeof props !== "object" || typeof resultado !== "object") throw new Error(msjSinRegistros ? msjSinRegistros : "No se encontraron resultados")*/
                let { paginas, listado, registros } = resultado;

                
                /*if(!Array.isArray(listado) || listado.length === 0) throw new Error(msjSinRegistros ? msjSinRegistros : "No se encontraron resultados")*/
                let acciones = actions;
                let newData = listado;
                setPaginacion(paginacion => ({
                    ...paginacion,
                    registros,
                    paginas
                }));
                setData([...newData]);
                setDataFiltrada([...newData]);
                if (newData.length == 0) {
                    setError({
                        mensaje: msjSinRegistros
                            ? msjSinRegistros
                            : "No se encontraron resultados",
                        ok: true
                    });
                }


        if(paginas < paginaActiva)
        {
            setPaginaActiva(paginas)
        }

            } catch ({ message: mensaje }) {
                alert("Error de conexión");
            }
            setLoader(false);
        }
        effect();
    }
    useEffect(ftEffect, [
        paginaActiva,
        paginacion.limite,
        paginacion.busqueda,
        actualizar,
        actualizarTbl,
        /* busqueda.busqueda, */
        location.search,
        token
    ]);

    //Al renderizar el componente mostrarse en el top de la pagina
    useEffect(() => {  
        const element = document.getElementById("root");
        element.scrollIntoView(true);
    });
    

    /* useEffect(() => {
        let subgrupos = paginacion.busqueda.subgruposIDs;
        let filtrosSub = [];
        filtrosSub = filtro.subgruposIDs ? filtro.subgruposIDs.data : [];
        setError(error => ({ mensaje: "", ok: false }));

        if (subgrupos && filtrosSub) {
            let count = subgrupos.length;
            subgrupos.map((item, index) => {
                let indexFiltros = filtrosSub
                    .map(item2 => item2.value)
                    .indexOf(item.toString());
                let subIDS = subgrupos;
                if (indexFiltros < 0) {
                    delete subgrupos[index];
                    if (count == 1) {
                        subgrupos = [];
                    }
                    setPaginacion(paginacion => ({
                        ...paginacion,
                        busqueda: {
                            ...paginacion.busqueda,
                            subgruposIDs: subgrupos
                        }
                    }));
                }
            });
        }
        if (
            busqueda &&
            Object.keys(busqueda).length > 0 &&
            CompararDosObjetos(search, busqueda)
        )
            return;
        if (paginaActiva > 1) setPaginaActiva(1);
        else ftEffect();
    }, [busqueda, paginacion]); */

    function handleFiltersDepartamentos(value) {
        if (paginacion.busqueda.gruposIDs.includes(value)) {
            let auxFilter = paginacion.busqueda.gruposIDs.indexOf(value);
            paginacion.busqueda.gruposIDs.splice(auxFilter, 1);
            let newFilter = paginacion.busqueda.gruposIDs;
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ...paginacion.busqueda,
                    ["gruposIDs"]: newFilter
                }
            }));
        } else {
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ...paginacion.busqueda,
                    ["gruposIDs"]: [...paginacion.busqueda.gruposIDs, value]
                }
            }));
        }
    }

    function handleFiltersCategorias(value) {
        if (paginacion.busqueda.subgruposIDs.includes(value)) {
            let auxFilter = paginacion.busqueda.subgruposIDs.indexOf(value);
            paginacion.busqueda.subgruposIDs.splice(auxFilter, 1);
            let newFilter = paginacion.busqueda.subgruposIDs;
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ...paginacion.busqueda,
                    ["subgruposIDs"]: newFilter
                }
            }));
        } else {
            setPaginacion(paginacion => ({
                ...paginacion,
                busqueda: {
                    ...paginacion.busqueda,
                    ["subgruposIDs"]: [
                        ...paginacion.busqueda.subgruposIDs,
                        value
                    ]
                }
            }));
        }
    }

    function constructFilters(mostrarFiltros, filtro) {
        let auxDepartamentos;
        let auxCategorias;
        let departamentos;
        let categorias;
        let filtros;
        let filtrosDepartamentos;
        let filtrosCategorias;
        let auxFilterIndex;
        if (mostrarFiltros) {
            auxDepartamentos = filtro.gruposIDs.data;
            auxCategorias = filtro.subgruposIDs.data;

            if (auxDepartamentos !== [] && auxDepartamentos !== undefined) {
                departamentos = auxDepartamentos.map((element, index) => {
                    return (
                        <li>
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        handleFiltersDepartamentos(
                                            element.value
                                        );
                                    }}
                                />
                                <h5>{element.label}</h5>
                            </label>
                        </li>
                    );
                });
            }

            if (auxCategorias !== [] && auxCategorias !== undefined) {
                categorias = auxCategorias.map((element, index) => {
                    return (
                        <li>
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        handleFiltersCategorias(element.value);
                                    }}
                                />
                                <h5>{element.label}</h5>
                            </label>
                        </li>
                    );
                });
            }

            filtrosDepartamentos = (
                <div
                    className="contenedorFiltrosDepartamentos"
                    onClick={() => {
                        setOpenFiltroDepartamentos(!openFiltroDepartamentos);
                    }}
                >
                    <ul>
                        <div className="buttonFilter">
                            <h3>Departamentos</h3>
                        </div>
                        <div
                            className="contenedorListaFiltrosDepartamentos"
                            style={{
                                display: openFiltroDepartamentos
                                    ? "flex"
                                    : "none"
                            }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            {departamentos}
                        </div>
                    </ul>
                </div>
            );

            filtrosCategorias = (
                <div
                    className="contenedorFiltrosCategorias"
                    onClick={() => {
                        setOpenFiltroCategorias(!openFiltroCategorias);
                    }}
                >
                    <ul>
                        <div className="buttonFilter">
                            <h3>Categorías</h3>
                        </div>
                        <div
                            className="contenedorListaFiltrosCategorias"
                            style={{
                                display: openFiltroCategorias ? "flex" : "none"
                            }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            {categorias}
                        </div>
                    </ul>
                </div>
            );

            filtros = (
                <>
                    {filtrosDepartamentos}
                    {filtrosCategorias}
                </>
            );
            return filtros;
        } else {
            return <div>No hay filtros para mostrar</div>;
        }
    }

    function headerTable(mostrarHeader, data) {
        return (
            <>
                <h3>PRODUCTOS</h3>
            </>
        );
    }

    function handleViewProducto(id){
        let parametros = `{"id": "${id}"}`;
        let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&");
        let urlProducto = `/producto/${url}`;
        history.push(urlProducto);
    }

    function tablaCompleta(data) {
        let cards = data?.map(element => {
            return (
                <>
                    <CardProducto 
                        element={element}
                        handleViewProducto={handleViewProducto}
                        agregarListaDeseos={agregarListaDeseos}
                    /> 
                </>
            );
        });
        return cards;
    }

    function agregarListaDeseos({ deseosID, productoID }) {
        if (usuario !== null) {
            ftAgregar({ deseosID, productoID });
        }
        else {
            //setFavorito(likeFavorito => (true));
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }

    async function ftAgregar({ deseosID, productoID }) {
        try {
            let deseo = await upsertDeseos({
                input: { deseosID, productoID: Number(productoID), clienteID: usuario.usuarioID }
            }, token);

            let msj = deseo ? `Producto agregado a sus favoritos` : `Se quitó el producto de sus favoritos`;

            setActualizarTbl(!actualizarTbl);
            setAlertGenerica({
                visible: true,
                descripcion: msj,
                tipo: "success",
            });
        } catch (error) {
            setAlertGenerica({
                visible: true,
                descripcion: `Error: ${error}`,
                tipo: "error",
            });
        }
    }

     function ftHandlePaginacionRegistros(){

        let auxContructor = [];

        for(let i = 1; i <= paginacion.paginas; i++){
            auxContructor.push(<>
            <button 
                onClick={() => {setPaginaActiva(i)}}
                className={paginaActiva === i ? "paginaActiva" : ""}
                >{i}</button>
        </>)
        }

        flechasCambioPagina && (auxContructor = [
            <button 
                className={"flecha " + (paginaActiva === 1 ? "hide" : "")} 
                onClick={() => {setPaginaActiva(p => p-1)}}>&#10094;
            </button>,
            ...auxContructor,
            <button 
                className={"flecha " + (paginaActiva === paginacion.paginas ? "hide" : "")} 
                onClick={() => setPaginaActiva(p => p+1)}>&#10095;
            </button>,
        ])

        return (<>{auxContructor}</>)
    }

    function handleChange(e) {
        const { value } = e.target;
        setPaginacion(prevState => {
            return {
                ...prevState,
                limite: parseInt(value)
            };
        });
    }


    function constructorTabla() {
        return (
            <div className="contenedorGeneralTablaProductos">
                <div className="contenedorInternoTablaProductos">
                    
                    {/* <div className="contenedorFiltros">
                        {constructFilters(mostrarFiltros, filtro)}
                        <div className="filtroPrecios">
                            <h3>Filtro por precio</h3>
                            <input
                                type="range"
                                min="1"
                                max="35000"
                                value={''}
                            />
                            
                        </div>
                    </div> */}
                    <div className="contenedorEncabezadoTablaProductos">
                        <div className="contenedorEncabezado">
                            {headerTable()}
                        </div>
                        <div className="contenedorTablaProductos">
                            {tablaCompleta(data)}
                        </div>
                        <div className='listadosTablaProductos'>
                        {ftHandlePaginacionRegistros()}
                        </div>
                        <div className='cantidadRegistrosTablaProductos'> 
                        <span>Cantidad de registros a mostrar</span>
                        <br></br>
                        <select   onChange={e => handleChange(e)}>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={120}>120</option>
                            </select></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {constructorTabla()}

            {alertGenerica && <AlertaGenerica
                visible={alertGenerica.visible}
                descripcion={alertGenerica.descripcion}
                tipo={alertGenerica.tipo}
                handleClose={handleCloseMensaje}
            />}
            
            <PopUpLogin 
            openOpciones={openOpciones}
            setOpenOpciones={setOpenOpciones}
            />

                {
                    loader?
                    <LoaderLogoSoftwone2 />
                    :
                    <></>
                }
        </>
    );
}
