import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { agregarArticuloRedux, setCarrito, modificarProductoRedux } from '../../../redux/actions/carritoActions'

import AlertaGenerica from '../../../_layout/genericComponents/AlertaGenerica';
import { Switch, Redirect, useParams } from "react-router-dom";
import { Encriptar } from '@renedelangel/helpers';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";

// material-ui icons
import Boton from '../../../_layout/components/CustomButtons/Button.js';
import CarruselDetalle from '../../../_layout/components/CarruselDetalle/index.js'

import Login from '../../Login';
import Modal from '../../../_layout/genericComponents/Modal';
import Preloader from '../../../_layout/genericComponents/Preloader';
//import ProductosAlternativos from './ProductosAlternativos';
import CardFilter from '../../../_layout/masterComponents/CardFilter'

import { getProducto } from '../../../querys/Productos/metodos';
import { upsertDeseos } from '../../../mutations/Deseos/metodos';
import styles from '../../../_layout/assets/jss/material-dashboard-pro-react/components/detalleProductos';
import noImage from '../../../_layout/assets/svg/undraw_blank_canvas_3rbb.svg';
import { animateScroll as scroll } from "react-scroll";
// Componentes Custom
import { redondeo } from '../../../helpers/regexp.js'
import { danger } from "../../../_layout/helpers/colores";
import { tablaProductosAlternativos } from '../../../querys/Productos/metodos';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(styles);

//querys
import { upsertCarrito } from '../../../querys/Carrito/index';

const { Encrypt, Decrypt } = Encriptar;

function Detalles() {
    let { id } = useParams();
    let url = id.replace(/&/g, "/");
    let seed = "softwone";
    const classes = useStyles();
    const noTruncarDecimales = { maximumFractionDigits: 20 };

    let datos = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
    let productoID = datos.id;

    const { usuario, token } = useSelector(state => state.login);
    const { usuarioID } = usuario ? usuario : {};
    const { busqueda } = useSelector(state => state.busqueda);
    const dispatch = useDispatch();
    const agregarItemRedux = (item) => dispatch(agregarArticuloRedux(item));
    const modificarItemRedux = (item) => dispatch(modificarProductoRedux(item));
    const { carrito } = useSelector(state => state.carrito);
    const setCarritoRedux = (carritoUsuario) => dispatch(setCarrito(carritoUsuario));
    if (!carrito) {
        setCarritoRedux([]);
    }
    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    const cleanOpenOpciones = { open: false, formulario: "", descripcion: "" };
    const [state, setState] = useState({ productoID: null });
    const [openOpciones, setOpenOpciones] = useState(cleanOpenOpciones);
    const [carrusel, setCarrusel] = useState(null);
    const [alertGenerica, setAlertGenerica] = useState(defaultMensaje);
    const [count, setCount] = useState(1);
    const [principal, setPrincipal] = useState({ imagen: null, leyenda: "0/0" });
    const [likeFavorito, setFavorito] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [actualizarCard, setActualizarCard] = useState({ id: 0, like: 0 });
    const [redirigir, setRedirigir] = useState(null);

    const agregarAlCarrito = (carritoID) => {
        let item = {
            "carritoID": carritoID,
            "usuarioID": usuarioID ? Number(usuarioID) : null,
            "productoID": Number(productoID),
            "imagenPortada": state.urlImagen1,
            "codigoProducto": state.codigoProducto,
            "nombre": state.descripcionCorta,
            "descripcion": state.descripcion,
            "cantidad": Number(count),
            "precioNormalSinIVA": Number(state.precioNormalSinIva),
            "IVA": Number(state.precioNormalSinIva) * Number(state.porcIva / 100),
            "precioVentaOnline": Number(state.precioVentaOnline),
            "servicio": state.servicio,
        }
        let index = carrito.map(item => item.productoID).indexOf(Number(productoID));
        if (index > -1) {
            let cant = Number(carrito[index].cantidad) + Number(count);
            let item = carrito[index];
            let nuevo = { ...item, cantidad: cant }
            modificarItemRedux(nuevo);
        } else {
            agregarItemRedux(item);
        }
        setActualizar(true);
    }
    const incrementaCantidad = (sumar) => {
        let cantidad = count;
        cantidad = Number(cantidad) + Number(sumar);
        validaExistencia(cantidad);
    }
    const changeCantidad = e => {
        let cant = e.target.value;
        validaExistencia(cant);
    }
    function validaExistencia(cantidad) {

        if (cantidad < 1) {
            setCount(1);
            return;
        }
        setCount(cantidad);
        if (state.vntaExistCero == 0) {
            if (cantidad > state.existencia) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `La cantidad ${cantidad} sobrepasa la existencia disponible (${state.existencia})`,
                    tipo: "error",
                });
                setCount(state.existencia);
                return;
            }
        }
    }
    function ftAgregarItem() {
        async function ftAgregarItem() {
            try {
                if (state.vntaExistCero == 0) {
                    if (count > state.existencia) {
                        setAlertGenerica({
                            visible: true,
                            descripcion: `La cantidad ${count} sobrepasa la existencia disponible (${state.existencia})`,
                            tipo: "error",
                        });
                        return;
                    }
                }
                if (usuario !== null) {
                    let index = carrito.map(item => item.productoID).indexOf(Number(productoID));
                    let cant = count;
                    if (index > -1) {
                        cant = Number(carrito[index].cantidad) + Number(count);
                    }
                    let carritoSelec = await upsertCarrito({
                        input: { productoID: Number(productoID), cantidad: Number(cant) }
                    }, token);
                    if (carritoSelec) {
                        agregarAlCarrito(carritoSelec.carritoID);
                    }
                } else {
                    agregarAlCarrito(null);
                }
                setAlertGenerica({
                    visible: true,
                    descripcion: `Producto agregado a tu carrito de compras`,
                    tipo: "success",
                });
            }
            catch (error) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `Error ${error}`,
                    tipo: "error",
                });
            }
        }
        return ftAgregarItem();
    }
    function ftAgregar() {
        async function ftAgregar() {
            try {
                let deseo = await upsertDeseos({
                    input: { deseosID: state.deseosID, productoID: Number(state.productoID), clienteID: usuario.usuarioID }
                }, token);
                let msj = deseo ? `Producto agregado a sus favoritos` : `Se quitó el producto de sus favoritos`
                setState({ ...state, deseosID: deseo })
                setAlertGenerica({
                    visible: true,
                    descripcion: msj,
                    tipo: "success",
                });
            } catch (error) {
                setAlertGenerica({
                    visible: true,
                    descripcion: `Error: ${error}`,
                    tipo: "error",
                });
            }
        }
        return ftAgregar();
    }
    function agregarListaDeseos() {
        if (usuario !== null) {
            ftAgregar();
        }
        else {
            setFavorito(likeFavorito => (true));
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }
    function agregarListaDeseosProducto() {
        if (!usuario) {
            setOpenOpciones(openOpciones => ({
                ...openOpciones,
                open: !openOpciones.open,
                formulario: "login",
                descripcion: "Iniciar sesión"
            }));
        }
    }
    function getRederigir(url = "/") {
        return (<><Switch><Redirect to={url} /></Switch></>);
    }
    const filtraGrupo = () => {
        setGruposRedux({ gruposIDs: [state.grupoID.toString()] })
        setRedirigir(getRederigir(`/productos`));
    }
    const filtraSubGrupo = () => {
        setBusquedaRedux({ subgruposIDs: [state.subgrupoID.toString()] })
        setRedirigir(getRederigir(`/productos`));
    }
    function direccionar(datos) {
        let seed = "softwone";
        const parametros = `{"id": "${datos.id}"}`;
        let url = Encrypt({ data: parametros, seed }).replace(/\//g, "&")
        setRedirigir(getRederigir(`${url}`))
    }
    const infoSeccion = {
        title: 'Productos similares',
        //icon: <ShoppingBasketIcon />,
        actualizar,
        actualizarCard,
        props: {
            id: "productoID",
            title: "descripcionCorta",
            descripcion: "descripcion",
            imagen: "imagenPortada",
            textFooterLeft: "precioVentaOnline",
            deseosID: "deseosID"
        },
        actions: [
            {
                idIcono: 1,
                color: danger,
                descripcion: "Agregar a lista de deseos",
                icono: { "id": FavoriteOutlinedIcon, "sin": FavoriteBorderIcon },
                // onChange: ({target:{icono}}) => handleChange(icono, "comprador"),
                onClick: (datos) => agregarListaDeseosProducto(datos),
            }
        ],
        filtro: {
            departamentosIDs: {
                title: "Departamentos",
                data: null
            },
            gruposIDs: {
                title: "Categorías",
                data: null
            }
        },
        busqueda: { busqueda },
        formato: [{ columnas: ["precioVentaOnline"], tipo: "moneda" }],
        msjSinRegistros: "Al parecer no hemos encontrado ningún producto con las características que estás buscando"
    };
    function ftEffect() {
        async function effect() {
            try {
                let producto = await getProducto({ productoID: productoID }, token);
                if (!producto.productoID) {
                    return;
                }
                let precio = producto.precioVentaOnline ? producto.precioVentaOnline : 0;
                precio = redondeo(precio, 2);
                precio = precio.toLocaleString('en-US', noTruncarDecimales);
                precio = `$ ${precio}`;
                producto.precioDetalle = precio;

                let imagenes = [];
                let total = producto.urlImagen4 ? 4 : producto.urlImagen3 ? 3 : producto.urlImagen2 ? 2 : producto.urlImagen1 ? 1 : 0;

                if (producto.urlImagen1 !== "") {
                    imagenes.push({ imagen: producto.urlImagen1, leyenda: `1 / ${total}`, orden: 0 });
                } else {
                    imagenes.push({ imagen: noImage, leyenda: `1 / ${total}`, orden: 0 });
                }
                if (producto.urlImagen2 !== "") imagenes.push({ imagen: producto.urlImagen2, leyenda: `2 / ${total}`, orden: 1 });
                if (producto.urlImagen3 !== "") imagenes.push({ imagen: producto.urlImagen3, leyenda: `3 / ${total}`, orden: 2 });
                if (producto.urlImagen4 !== "") imagenes.push({ imagen: producto.urlImagen4, leyenda: `4 / ${total}`, orden: 3 });

                setCarrusel(imagenes);
                setPrincipal({ imagen: imagenes[0].imagen, leyenda: imagenes[0].leyenda });
                setState(producto);
                scrollToTop();

            } catch ({ message }) {
                console.error('Error en el efect Detalle del producto', message);
            }
        } effect();
    } useEffect(ftEffect, [productoID, actualizar]);

    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setAlertGenerica({
            ...alertGenerica,
            visible: false
        });
    };
    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    /**
     * <Boton title="Agregar al carrito"  color="info" className={classes.botonAgregarResponsivo} onClick={() => {ftAgregarItem()}}>
                            Comprar
                        </Boton>
     */
    return (
        state ? <>
            {redirigir}
            <Grid container className={classes.panelProducto}>

                <Card className={classes.panelImagenes}>
                    <CarruselDetalle
                        imagenes={carrusel}
                        portada={principal}
                    />
                </Card>
                <Card className={classes.panelDetalle}>
                    <p className={classes.link}><Link to={{
                        pathname: "/productos",
                        state: { grupoParam: state.grupoID }
                    }}>{state.grupo}</Link><b>{`  /  `}</b>
                        <Link to={{
                            pathname: "/productos",
                            state: { grupoParam: state.grupoID, subgrupoParam: state.subgrupoID }
                        }}>{state.subgrupo}</Link>
                    </p>
                    <p className={classes.titulo}>{state.descripcionCorta}</p>
                    <p ><span>Código#</span>{state.codigoProducto}</p>
                    <p className={classes.precioDetalle}>{state.precioDetalle}</p>

                    <div className={classes.acciones}>
                        Cantidad
                        <button type="submit" className={classes.botonReducir} onClick={() => { incrementaCantidad(-1) }}>-
                        </button>
                        <input type="text"
                            className={classes.cantidadCarrito}
                            value={count}
                            onChange={(e) => changeCantidad(e)}
                        />
                        <button type="submit" className={classes.botonIncremento} aria-label="increase" onClick={() => { incrementaCantidad(1) }}>+</button>
                        <Boton color="info" className={classes.botonAgregarCarrito} onClick={() => { ftAgregarItem() }}>
                            Agregar al carrito
                        </Boton>
                        <Boton title="Agregar al carrito" color="info" className={classes.botonAgregarResponsivo} onClick={() => { ftAgregarItem() }}>
                            Comprar
                        </Boton>

                        <Tooltip title={state.deseosID ? "Quitar de mis favoritos" : "Agregar a mis favoritos"}>
                            <Button onClick={() => { agregarListaDeseos() }}>
                                {
                                    state.deseosID ? <FavoriteOutlinedIcon color="secondary" /> : <FavoriteBorderIcon color="secondary" />
                                }
                            </Button>
                        </Tooltip>
                        <p>Estado {state.vntaExistCero ? `(Siempre disponible)` : `(${state.existencia}  disponibles)`}</p>
                    </div>
                    <span className={classes.etiqueta}>Descripción</span>
                    <div className={classes.descripcion}>{state.descripcion}</div>
                </Card>
                <CardFilter
                    mostrarFiltros={false}
                    asyncData={tablaProductosAlternativos}
                    infoSeccion={infoSeccion}
                    token={token}
                    parametrosFijos={{ limite: 16, dominio: window.location.hostname.split('.')[0], productoID: Number(productoID) }}
                    actualizar={actualizar}
                />
            </Grid>

            {alertGenerica && <AlertaGenerica
                visible={alertGenerica.visible}
                descripcion={alertGenerica.descripcion}
                tipo={alertGenerica.tipo}
                handleClose={handleCloseMensaje}
            />}
            <Modal
                title={openOpciones.descripcion}
                open={openOpciones.open}
                onClose={() => setOpenOpciones(cleanOpenOpciones)}
            >
                <Login onClose={() => setOpenOpciones(cleanOpenOpciones)} />
            </Modal>
        </> : <Preloader />
    );

}
export default Detalles;
