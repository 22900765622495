import { FetchAPI } from "@renedelangel/helpers";
import { matchPath } from "react-router-dom/cjs/react-router-dom.min";
import { NetworkError } from "_layout/genericComponents/Metodos";
import { urlServidor, strUrlSArchivos } from '../../helpers';

//CONSULTAS
export const getCarrito = async (variables, token) => {

    let query = `
        query listadoCarrito($usuarioID:ID!) {
            listadoCarrito(id:$usuarioID){
               paginas
                registros
                listado{
                carritoID
                usuarioID
                productoID
                codigoProducto
                cantidad
                nombre
                descripcion
                precioNormalSinIVA
                IVA
                precioNormalConIVA
                imagenPortada
                servicio
                producto
                tipoPaquete
                plazoPaquete
                licenciaUnica
                precioVentaOnline
                precioVentaOnlineSinIva
                porcIva
                }
            }

        }
    `;
    let resultado = await ejecutar(variables, token, query);
    let listado = resultado.listado;
    let listado2 = listado.map(({ imagenPortada,  ...rest }) => {
                
                let rutaPortada = imagenPortada;
                let imagen =  `${strUrlSArchivos}${'/public/tonline'}${imagenPortada}`;
                let portada = imagenPortada ? imagen : "";
                rest.IVA = rest.precioVentaOnline -  rest.precioVentaOnlineSinIva;
                rest.porcIva = Math.round((rest.IVA / rest.precioVentaOnlineSinIva) * 100);

                return {
                    ...rest,
                    imagenPortada: portada,
                    rutaPortada
                }
            })
    resultado.listado = listado2;

    return resultado;
}
//MUTATION
export const deleteCarrito = async (variables, token) => {

    let query = `
    mutation deleteProductoCarrito($carritoID: ID!) {
        deleteProductoCarrito(id: $carritoID)
    }
    `;
     return await ejecutar(variables, token, query);
}
export const upsertCarrito = async (variables, token) => {

    let query = `mutation upsertCarrito($input: InputUpsertCarrito!) {
        upsertCarrito(input: $input){
        usuarioID
        carritoID
        productoID
        codigoProducto
        cantidad
        nombre
        descripcion
        precioNormalSinIVA
        IVA
        precioNormalConIVA
       }
    }
    `;
    return await ejecutar(variables, token, query);
}
// FIN MUTATION

export const ejecutar = async (variables, token, query) => {
    try {
        let  respuesta  = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return respuesta[Object.keys(respuesta)[0]];

    } catch({message}) { throw new Error(message); }
}

